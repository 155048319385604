import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RechercheAvancee from './RechercheAvancee';
import ListeDoc from './ListeDoc';
import DocumentInfo from './DocumentInfo';
import DocumentVisioneuse from './DocumentVisioneuse';
import DocumentParcours from './DocumentParcours';
import HighlightConfigurator from '../highlight/HighlightConfigurator.js';
import Graphe from './Graphe';
import SelectAffichage from './SelectAffichage';
import Constante from '../Constante';
import AjaxGestion from '../AjaxGestion';
/*import logo from './logo.svg';*/
import DialogAlert from '../mes_alertes/DialogAlert';
import DialogProjetTreebeard from './dialogprojet/DialogProjetTreebeard';
import DialogProjetDeleteDoc from './dialogprojet/DialogProjetDeletedoc';
import { getSession } from '../utils/auth';

import './Recherche.css';

class Recherche extends Component {

  constructor(props) {

    super(props);

    this.CPN = {};
    this.CTGN = {};
    this.CPInfo = {};
    this.dialogAlert = React.createRef();
    this.dialogProjetTreebeard = React.createRef();
    this.dialogSuppDocListWorkfile = React.createRef();
    this.graph = React.createRef();
    this.rechercheavancee = React.createRef();
    this.DocumentInfoRef = React.createRef();
    this.histo_save = true;
    this.calculConceptLaunch = false;
    this.state = {
      AlertDialogType: 0,
      alertCount: 0,
      DocumentsInfo: {
        Recherche1: {
          AjaxRecherche: Constante.AjaxChoix.listeDoc,
          Documents: [], //liste des docs
          DocumentsSelect: [], // contiens l'apid
          DocumentsSelectREP: [], // idem que doc select mais avec le REP
          DocumentActif: 0,
          NBDocs: 0,
          NBPages: 0,
          PageEnCours: 1,
          etat: Constante.etat.initial // permet de connaitre l'état de Recherche
        },
        Recherche2: {
          AjaxRecherche: Constante.AjaxChoix.listeDocNPL,
          Documents: [], //liste des docs
          DocumentsSelect: [], // contiens l'apid
          DocumentsSelectREP: [], // idem que doc select mais avec le REP
          DocumentActif: 0,
          NBDocs: 0,
          NBPages: 0,
          PageEnCours: 1,
          etat: Constante.etat.initial // permet de connaitre l'état de Recherche
        },
        Ordre: Constante.RequeteOrdre.Relevance,
        NBResultat: 25,
      },
      documentUn: {
        data: {},
        etat: Constante.etat.initial,
        position: 0
      },
      affichage: {
        mode: Constante.affichageMode.rechercheavancee,
        select: Constante.AffichageSelect.graphe,
        graphe: 1,
        document: 0,
        visioneuse: 0,
        RechercheAvancee: 1,
        droite: 1
      }
    }
    if (window.innerWidth < 1365) {
      this.state.affichage.RechercheAvancee = 2;
    }
  }

  static propTypes = {
    langue: PropTypes.object,
    ticket: PropTypes.string,
    tokenNPL: PropTypes.string,
    langueProjets: PropTypes.array,
    highlightsProfile: PropTypes.object,
    user: PropTypes.object,
    RequeteInfosListe: PropTypes.array,
    RequeteInfos: PropTypes.object,
    RequeteInfosActif: PropTypes.object,
    RechercheInfo: PropTypes.object,
    permalink: PropTypes.bool,
    xpn: PropTypes.string,
    base: PropTypes.string,
    watchingListId: PropTypes.string,
    affichage_page_mode: PropTypes.string,
    clearRechercheEmplacement: PropTypes.func,
    changeOnglet: PropTypes.func,
    changement_requete_avancee: PropTypes.func,
    similariteSearch: PropTypes.func,
    setwatchingListId: PropTypes.func,
    RequeteInfosSave: PropTypes.func,
    updateHighlightsProfile: PropTypes.func,
    requeteHistoryAction: PropTypes.func,
    set_emplacement_recherche: PropTypes.func,
    active_beforeunloadfct: PropTypes.func
  }

  componentDidMount = () => {
    if (this.props.permalink) {
      this.getPermalink();
    }
    else {
      this.alertGetCount();
      if (this.props.affichage_page_mode !== this.state.affichage.mode && this.props.affichage_page_mode !== null && typeof this.props.affichage_page_mode !== 'undefined') {
        this.changeSelectOuMode(null, this.props.affichage_page_mode);
      }
    }
  }

  addDocListWorkfile = () => {
    this.dialogProjetTreebeard.current.dialogVisible(true);
    this.forceUpdate();
  }

  addDocListWorkfileValider = (P_treenode) => {
    var L_variable = {
      ticket: this.props.ticket,
      shard: this.props.shard,
      id: P_treenode.id,
      EmplacementRecherche: P_treenode.type,
      DocumentsSelect: this.state.DocumentsInfo.Recherche1.DocumentsSelect
    }
    if (this.state.affichage.mode === Constante.affichageMode.listedoc) {
      L_variable.DocumentsSelect = [this.state.documentUn.data.APID];
    }

    AjaxGestion(Constante.AjaxChoix.listAdd, L_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Error data !');
        return;
      }
      if (typeof data.erreur !== 'undefined') {
        console.log('Error code :' + data.erreur + ' info :' + data.log);
        return;
      }
      this.dialogProjetTreebeard.current.dialogFermer();
      alert(this.props.langueProjets[20].LIB);
    });
  }


  changeDroiteRechercheAvanceeClose = () => {
    let L_affichage = Object.assign({}, this.state.affichage);
    L_affichage.RechercheAvancee = 0;
    this.setState({
      affichage: L_affichage
    });
  }

  changeDroiteRechercheAvancee = () => {
    let L_affichage = Object.assign({}, this.state.affichage);
    if (L_affichage.RechercheAvancee === 1) {
      L_affichage.RechercheAvancee = 0;
    }
    else {
      L_affichage.RechercheAvancee = 1;
    }
    this.setState({
      affichage: L_affichage
    });
  }

  changeDroiteAffichage = () => {
    let L_affichage = Object.assign({}, this.state.affichage);
    if (L_affichage.droite === 1) {
      L_affichage.droite = 0;
    }
    else {
      L_affichage.droite = 1;
    }
    this.setState({
      affichage: L_affichage
    });
  }

  changeNbresultat = value => {
    let L_documentsInfo = Object.assign({}, this.state.DocumentsInfo);
    L_documentsInfo.NBResultat = Number(value);
    this.setState({
      DocumentsInfo: L_documentsInfo
    });
    this.getSearch(null, L_documentsInfo, true, false, true);
  }

  changeOrdre = value => {
    let L_documentsInfo = Object.assign({}, this.state.DocumentsInfo);
    L_documentsInfo.Ordre = value;
    this.setState({
      DocumentsInfo: L_documentsInfo
    });
    this.getSearch(null, L_documentsInfo, true, false, true);
  }


  changeImageDocUn = P_pos => {
    let L_documentsUnInfo = Object.assign({}, this.state.documentUn);
    if (L_documentsUnInfo.data !== null) {
      L_documentsUnInfo.data.PosImage = P_pos;
      this.setState({
        documentUn: L_documentsUnInfo
      });
    }
  }

  changePage = P_avance => {
    let L_documentsInfo = Object.assign({}, this.state.DocumentsInfo);
    L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours += P_avance;
    if (L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours < 1) {
      L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours = 1;
    }
    else if (L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours > L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBPages) {
      L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours = L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBPages;
    }
    this.setState({
      DocumentsInfo: L_documentsInfo
    });
    this.getSearch(null, L_documentsInfo, false, P_avance, null);
  }

  changeSelectOuMode = (P_select, P_mode_affichage) => { // passer du mode : stat document visioneuse recherche_pavancee
    let L_affichage = Object.assign({}, this.state.affichage);

    if (P_mode_affichage !== null && typeof P_mode_affichage !== 'undefined') {
      L_affichage.mode = P_mode_affichage;

      switch (L_affichage.mode) {
        case Constante.affichageMode.listedoc:
          L_affichage.droite = 1;
          L_affichage.RechercheAvancee = 0;
          break;
        case Constante.affichageMode.rechercheavancee:
          L_affichage.droite = 1;
          L_affichage.RechercheAvancee = 1;
          break;
        case Constante.affichageMode.Recherche:
          L_affichage.droite = 0;
          L_affichage.RechercheAvancee = 1;
          break;
        default:
          break;
      }
    }

    if (P_select !== null && typeof P_select !== 'undefined') {
      L_affichage.select = P_select;
      switch (L_affichage.select) {
        case Constante.AffichageSelect.graphe:
          if (this.props.user.acces.graphe) {
            L_affichage.graphe = 1;
            L_affichage.document = 0;
            L_affichage.visioneuse = 0;
          }
          else {
            L_affichage.graphe = 0;
            L_affichage.document = 1;
            L_affichage.visioneuse = 0;
          }
          break;
        case Constante.AffichageSelect.document:
          L_affichage.graphe = 0;
          L_affichage.document = 1;
          L_affichage.visioneuse = 0;
          break;
        case Constante.AffichageSelect.visioneuse:
          L_affichage.graphe = 0;
          L_affichage.document = 0;
          L_affichage.visioneuse = 1;
          break;
        case Constante.AffichageSelect.documentEtVisioneuse:
          L_affichage.graphe = 0;
          L_affichage.document = 1;
          L_affichage.visioneuse = 1;
          break;
        default:
          break;
      }
    }

    this.setState(
      (prevState, props) => ({ affichage: L_affichage }),
      () => {
        setTimeout(this.activeDoc(null), 200);
      }
    );
  }

  clearDoc = (P_documents) => {
    if (!P_documents) {
        return [];
    }
    for (let l_parcours_doc = 0; l_parcours_doc < P_documents.length; l_parcours_doc++) {
      if (P_documents[l_parcours_doc].PN === null) {
        P_documents[l_parcours_doc].PN = '';
      }
      else {
        //P_documents[l_parcours_doc].PN = P_documents[l_parcours_doc].PN.replace(/<b>|<\/b>/gi,'');
      }
      if (P_documents[l_parcours_doc].TI === null) {
        P_documents[l_parcours_doc].TI = '';
      }
      else {
        //P_documents[l_parcours_doc].TI = P_documents[l_parcours_doc].TI.replace(/<b>|<\/b>/gi,'');
      }
      if (P_documents[l_parcours_doc].AB === null) {
        P_documents[l_parcours_doc].AB = '';
      }
      else {
        //P_documents[l_parcours_doc].AB = P_documents[l_parcours_doc].AB.replace(/<b>|<\/b>/gi,'');
      }
      if (P_documents[l_parcours_doc].PA === null) {
        P_documents[l_parcours_doc].PA = '';
      }
      else {
        //P_documents[l_parcours_doc].PA = P_documents[l_parcours_doc].PA.split('<br/>')[0].replace(/<b>|<\/b>/gi,'');
      }
      if (P_documents[l_parcours_doc].REP === null) {
        P_documents[l_parcours_doc].REP = '';
      }
      else {
        //P_documents[l_parcours_doc].REP = P_documents[l_parcours_doc].REP.replace(/<b>|<\/b>/gi,'');
      }
      if (P_documents[l_parcours_doc].EPRD === null) {
        P_documents[l_parcours_doc].EPRD = '';
      }
      else {
        //P_documents[l_parcours_doc].EPRD =  P_documents[l_parcours_doc].EPRD.replace(/<b>|<\/b>/gi,'');
      }
    }
    return P_documents;
  }

  clearDocNPL = (P_documents, P_count) => {
    for (let l_parcours_doc = 0; l_parcours_doc < P_documents.length; l_parcours_doc++) {
      P_documents[l_parcours_doc].POSITION = P_count;
      if (P_documents[l_parcours_doc].title === null || typeof P_documents[l_parcours_doc].title === 'undefined') {
        P_documents[l_parcours_doc].title = '';
      }
      else {
        //P_documents[l_parcours_doc].PN = P_documents[l_parcours_doc].PN.replace(/<b>|<\/b>/gi,'');
      }
      if (P_documents[l_parcours_doc].year === null || typeof P_documents[l_parcours_doc].year === 'undefined') {
        P_documents[l_parcours_doc].year = '';
      }
      else {
        //P_documents[l_parcours_doc].TI = P_documents[l_parcours_doc].TI.replace(/<b>|<\/b>/gi,'');
      }
      if (P_documents[l_parcours_doc].type === null || typeof P_documents[l_parcours_doc].type === 'undefined') {
        P_documents[l_parcours_doc].type = '';
      }
      if (P_documents[l_parcours_doc].subtype === null || typeof P_documents[l_parcours_doc].subtype === 'undefined') {
        P_documents[l_parcours_doc].subtype = '';
      }
      if (P_documents[l_parcours_doc].source_name === null || typeof P_documents[l_parcours_doc].source_name === 'undefined') {
        P_documents[l_parcours_doc].source_name = '';
      }
      if (P_documents[l_parcours_doc].status === null || typeof P_documents[l_parcours_doc].status === 'undefined') {
        P_documents[l_parcours_doc].status = 'unknow';
      }
      if (P_documents[l_parcours_doc].abstract_description === null || typeof P_documents[l_parcours_doc].abstract_description === 'undefined') {
        P_documents[l_parcours_doc].abstract_description = '';
      }
      P_documents[l_parcours_doc].abstract_description = P_documents[l_parcours_doc].abstract_description.replace(/\n/gi, "<br/>")
      if (P_documents[l_parcours_doc].date_publication === null || typeof P_documents[l_parcours_doc].date_publication === 'undefined') {
        P_documents[l_parcours_doc].date_publication = '';
      }
      else {
        P_documents[l_parcours_doc].date_publication = P_documents[l_parcours_doc].date_publication.substring(0, 10);
      }
      if (P_documents[l_parcours_doc].start === null || typeof P_documents[l_parcours_doc].start === 'undefined') {
        P_documents[l_parcours_doc].start = '';
      }
      else {
        P_documents[l_parcours_doc].start = P_documents[l_parcours_doc].start.substring(0, 10);
      }
      if (P_documents[l_parcours_doc].end === null || typeof P_documents[l_parcours_doc].end === 'undefined') {
        P_documents[l_parcours_doc].end = '';
      }
      else {
        P_documents[l_parcours_doc].end = P_documents[l_parcours_doc].end.substring(0, 10);
      }
      if (P_documents[l_parcours_doc].listOfOrganisation === null || typeof P_documents[l_parcours_doc].listOfOrganisation === 'undefined') {
        P_documents[l_parcours_doc].listOfOrganisation = [];
      }
      else {
        let L_organisation = [];
        for (let L_parcours_organisation = 0; L_parcours_organisation < P_documents[l_parcours_doc].listOfOrganisation.length; L_parcours_organisation++) {
          L_organisation.push(P_documents[l_parcours_doc].listOfOrganisation[L_parcours_organisation].listOfLongName);
        }
        P_documents[l_parcours_doc].organisations = L_organisation.join('&nbsp; &#8226; &nbsp;');
        if (P_documents[l_parcours_doc].organisations.length > 120) {
          P_documents[l_parcours_doc].organisations_court = P_documents[l_parcours_doc].organisations;//.substring(0, 120) + '...';
        }
        else {
          P_documents[l_parcours_doc].organisations_court = P_documents[l_parcours_doc].organisations;
        }
      }
      if (P_documents[l_parcours_doc].listOfKeyword === null || typeof P_documents[l_parcours_doc].listOfKeyword === 'undefined') {
        P_documents[l_parcours_doc].listOfKeyword = [];
      }
      if (P_documents[l_parcours_doc].issn === null || typeof P_documents[l_parcours_doc].issn === 'undefined') {
        P_documents[l_parcours_doc].issn = '';
      }
      if (P_documents[l_parcours_doc].listOfPubDomain === null || typeof P_documents[l_parcours_doc].listOfPubDomain === 'undefined') {
        P_documents[l_parcours_doc].listOfPubDomain = [];
      }
      if (P_documents[l_parcours_doc].doi === null || typeof P_documents[l_parcours_doc].doi === 'undefined') {
        P_documents[l_parcours_doc].doi = '';
      }
      if (P_documents[l_parcours_doc].source_url === null || typeof P_documents[l_parcours_doc].source_url === 'undefined') {
        P_documents[l_parcours_doc].source_url = '';
      }
      if (P_documents[l_parcours_doc].content_en === null || typeof P_documents[l_parcours_doc].content_en === 'undefined') {
        P_documents[l_parcours_doc].content_en = '';
      }
      if (P_documents[l_parcours_doc].study_status === null || typeof P_documents[l_parcours_doc].study_status === 'undefined') {
        P_documents[l_parcours_doc].study_status = '';
      }
      if (P_documents[l_parcours_doc].listOfStudyPhase === null || typeof P_documents[l_parcours_doc].listOfStudyPhase === 'undefined') {
        P_documents[l_parcours_doc].listOfStudyPhase = [];
      }
      if (P_documents[l_parcours_doc].listOfInterventionType === null || typeof P_documents[l_parcours_doc].listOfInterventionType === 'undefined') {
        P_documents[l_parcours_doc].listOfInterventionType = [];
      }
      if (P_documents[l_parcours_doc].has_results === null || typeof P_documents[l_parcours_doc].has_results === 'undefined') {
        P_documents[l_parcours_doc].has_results = '';
      }
      if (P_documents[l_parcours_doc].listOfStudyCondition === null || typeof P_documents[l_parcours_doc].listOfStudyCondition === 'undefined') {
        P_documents[l_parcours_doc].listOfStudyCondition = [];
      }
      if (P_documents[l_parcours_doc].study_purpose === null || typeof P_documents[l_parcours_doc].study_purpose === 'undefined') {
        P_documents[l_parcours_doc].study_purpose = '';
      }
      if (P_documents[l_parcours_doc].study_masking === null || typeof P_documents[l_parcours_doc].study_masking === 'undefined') {
        P_documents[l_parcours_doc].study_masking = '';
      }
      if (P_documents[l_parcours_doc].funder_type === null || typeof P_documents[l_parcours_doc].funder_type === 'undefined') {
        P_documents[l_parcours_doc].funder_type = '';
      }
      if (P_documents[l_parcours_doc].total_cost === null || typeof P_documents[l_parcours_doc].total_cost === 'undefined') {
        P_documents[l_parcours_doc].total_cost = '';
      }
      if (P_documents[l_parcours_doc].total_funding === null || typeof P_documents[l_parcours_doc].total_funding === 'undefined') {
        P_documents[l_parcours_doc].total_funding = '';
      }
      if (P_documents[l_parcours_doc].currency === null || typeof P_documents[l_parcours_doc].currency === 'undefined') {
        P_documents[l_parcours_doc].currency = '';
      }
      if (P_documents[l_parcours_doc].currencySymbol === null || typeof P_documents[l_parcours_doc].currencySymbol === 'undefined') {
        P_documents[l_parcours_doc].currencySymbol = '';
      }
      if (P_documents[l_parcours_doc].project_acronym === null || typeof P_documents[l_parcours_doc].project_acronym === 'undefined') {
        P_documents[l_parcours_doc].project_acronym = '';
      }


      if (P_documents[l_parcours_doc].subtype === 'Defensive Publication') {
        const newlinedDescription = P_documents[l_parcours_doc].abstract_description.replace(/<\s*\/?br\s*[\/]?>/gi, '\n').replace(P_documents[l_parcours_doc].title, ''),
          rdNumberRegExp = new RegExp(`^ ?(?:<br\/>)? ?([0-9]*)`, 'i'),
          RD_NUMBER = newlinedDescription.match(rdNumberRegExp);

        if (RD_NUMBER.length === 2) {
            P_documents[l_parcours_doc].source_url = "https://static.orbit.com/rd/" + RD_NUMBER[1].padStart(6, 0) + ".pdf";
      }
    }

    P_count++;
  }
    return P_documents;
  }

clearRechercheEmplacement = () => {
  this.reset_documents();
  this.reset_document_un();
  this.props.clearRechercheEmplacement(null);
}

clickGrapheButton = () => {
  if (this.state.affichage.select === Constante.AffichageSelect.graphe) {
    this.changeSelectOuMode(Constante.AffichageSelect.document, null)
  }
  else {
    this.changeSelectOuMode(Constante.AffichageSelect.graphe, null)
  }
}

deleteDocListWorkfile = () => {
  this.dialogSuppDocListWorkfile.current.dialogVisible(true);
  this.forceUpdate();
}

deleteDocListWorkfileValider = () => {
  var L_variable = {
    ticket: this.props.ticket,
    shard: this.props.shard,
    DocumentsSelect: this.state.DocumentsInfo.Recherche1.DocumentsSelect,
    IdRecherche: this.props.RechercheInfo.IdRecherche,
    emplacement: this.props.RechercheInfo.EmplacementRecherche1,
  }

  if (this.state.affichage.mode === Constante.affichageMode.listedoc) {
    L_variable.DocumentsSelect = [this.state.documentUn.data.APID];
  }

  AjaxGestion(Constante.AjaxChoix.listDelete, L_variable).then((data) => {
    if (data === null || typeof data === 'undefined') {
      console.log('Error data !');
      return;
    }
    if (typeof data.erreur !== 'undefined') {
      console.log('Error code :' + data.erreur + ' info :' + data.log);
      return;
    }
    this.reset_document_un();

    let L_documentsInfo = Object.assign({}, this.state.DocumentsInfo);
    for (let L_parcours_docs = 0; L_parcours_docs < L_documentsInfo.Recherche1.Documents.length; L_parcours_docs++) {
      if (L_documentsInfo.Recherche1.DocumentsSelect.indexOf(L_documentsInfo.Recherche1.Documents[L_parcours_docs].APID) > -1) {
        L_documentsInfo.Recherche1.Documents.splice(L_parcours_docs, 1);
        L_parcours_docs--;
      }
    }
    L_documentsInfo.Recherche1.NBDocs -= L_documentsInfo.Recherche1.DocumentsSelect.length;
    L_documentsInfo.Recherche1.NBPages = Math.ceil(L_documentsInfo.Recherche1.NBDocs / L_documentsInfo.NBResultat);
    L_documentsInfo.Recherche1.DocumentsSelect = [];
    L_documentsInfo.Recherche1.DocumentsSelectREP = [];
    this.setState({
      DocumentsInfo: L_documentsInfo
    });
    this.dialogSuppDocListWorkfile.current.dialogVisible(false);
  });
}

commentAddValider(P_variables) {
  let L_documents_un = Object.assign({}, this.state.documentUn);
  L_documents_un.etat = Constante.etat.encours;
  this.setState({
    documentUn: L_documents_un,
  });
  P_variables.ticket = this.props.ticket;
  P_variables.shard = this.props.shard;
  P_variables.id_doc = this.state.documentUn.data.ID;
  P_variables.emplacement = this.props.RechercheInfo.EmplacementRecherche1;
  P_variables.idrecherche = this.props.RechercheInfo.IdRecherche;
  AjaxGestion(Constante.AjaxChoix.commentAdd, P_variables).then((data) => {
    let L_documents_un = Object.assign({}, this.state.documentUn);
    if (data === null || typeof data === 'undefined') {
      console.log('Error data !');
    }
    else if (typeof data.erreur !== 'undefined') {
      console.log('Error code :' + data.erreur + ' info :' + data.log);
    }
    else if (data.note != null) {
      L_documents_un.data.Comment.push(data.note);
    }
    L_documents_un.etat = Constante.etat.charge;
    this.setState({
      documentUn: L_documents_un,
    });
  });
}

commentUpdateValider(P_variables) {
  // Delete comment and recreate a new one
  const P_variables_clone = {...P_variables};
  let L_documents_un = Object.assign({}, this.state.documentUn);
  L_documents_un.etat = Constante.etat.encours;
  this.setState({
    documentUn: L_documents_un,
  });
  P_variables_clone.ticket = this.props.ticket;
  P_variables_clone.shard = this.props.shard;
  P_variables_clone.id_doc = this.state.documentUn.data.ID;
  P_variables_clone.emplacement = this.props.RechercheInfo.EmplacementRecherche1;
  P_variables_clone.idrecherche = this.props.RechercheInfo.IdRecherche;
  AjaxGestion(Constante.AjaxChoix.commentDelete, P_variables_clone).then((data) => {
    let L_documents_un = Object.assign({}, this.state.documentUn);
    if (data === null || typeof data === 'undefined') {
      console.log('Error data !');
    }
    else if (typeof data.erreur !== 'undefined') {
      console.log('Error code :' + data.erreur + ' info :' + data.log);
    }
    else if (data.status === 'OK') {
      L_documents_un.data.Comment.splice(P_variables_clone.id_comment, 1)
    }
    // TO recreate a new comment
    this.commentUpdateValider2(P_variables_clone);
  });
}

commentUpdateValider2(P_variables) {
  let L_documents_un = Object.assign({}, this.state.documentUn);
  L_documents_un.etat = Constante.etat.encours;
  this.setState({
    documentUn: L_documents_un,
  });
  P_variables.ticket = this.props.ticket;
  P_variables.shard = this.props.shard;
  P_variables.id_doc = this.state.documentUn.data.ID;
  P_variables.emplacement = this.props.RechercheInfo.EmplacementRecherche1;
  P_variables.idrecherche = this.props.RechercheInfo.IdRecherche;
  AjaxGestion(Constante.AjaxChoix.commentAdd, P_variables).then((data) => {
    let L_documents_un = Object.assign({}, this.state.documentUn);
    if (data === null || typeof data === 'undefined') {
      console.log('Error data !');
    }
    else if (typeof data.erreur !== 'undefined') {
      console.log('Error code :' + data.erreur + ' info :' + data.log);
    }
    else if (data.note != null) {
      L_documents_un.data.Comment.push(data.note);
    }
    L_documents_un.etat = Constante.etat.charge;
    this.setState({
      documentUn: L_documents_un,
    });
  });
}

commentDeleteValider(P_variables) {
  let L_documents_un = Object.assign({}, this.state.documentUn);
  L_documents_un.etat = Constante.etat.encours;
  this.setState({
    documentUn: L_documents_un,
  });
  P_variables.ticket = this.props.ticket;
  P_variables.shard = this.props.shard;
  P_variables.id_doc = this.state.documentUn.data.ID;
  P_variables.emplacement = this.props.RechercheInfo.EmplacementRecherche1;
  P_variables.idrecherche = this.props.RechercheInfo.IdRecherche;
  AjaxGestion(Constante.AjaxChoix.commentDelete, P_variables).then((data) => {
    let L_documents_un = Object.assign({}, this.state.documentUn);
    if (data === null || typeof data === 'undefined') {
      console.log('Error data !');
    }
    else if (typeof data.erreur !== 'undefined') {
      console.log('Error code :' + data.erreur + ' info :' + data.log);
    }
    else if (data.status === 'OK') {
      L_documents_un.data.Comment.splice(P_variables.id_comment, 1)
    }
    L_documents_un.etat = Constante.etat.charge;
    this.setState({
      documentUn: L_documents_un,
    });
  });
}

commentMenuAction = (P_variables) => {
  switch (P_variables.type) {
    case Constante.dialogMode.add:
      this.commentAddValider(P_variables);
      break;
    case Constante.dialogMode.rename:
      this.commentUpdateValider(P_variables);
      break;
    case Constante.dialogMode.delete:
      this.commentDeleteValider(P_variables);
      break;
    default:
      break;
  }
}

exportDoc = P_code_export => {
  let L_format = 'XLSX';
  let L_profil = "EXPRESS_XLSX_DEFAULT"
  switch (P_code_export) {
    case Constante.Export.PDF:
      L_format = 'PDF';
      L_profil = "EXPRESS_FIRST_DEFAULT"
      break;
    default:
      L_profil = "EXPRESS_XLSX_DEFAULT"
      L_format = 'XLSX';
      break;
  }
  let L_var = {
    'ticket': this.props.ticket,
    'shard': this.props.shard,
    'query': this.props.RequeteInfosActif.requete,
    'Ordre': this.state.DocumentsInfo.Ordre,
    'apids': this.state.DocumentsInfo.Recherche1.DocumentsSelect,
    'format': L_format,
    'profil': L_profil,
    'EmplacementRecherche': this.props.RechercheInfo.EmplacementRecherche1
  };
  window.document.body.style.cursor = "wait";
  AjaxGestion(Constante.AjaxChoix.ExportDoc, L_var).then((data) => {
    //alert("exporte test");
    window.document.body.style.cursor = "default";
    window.open(data.data.url, '_blank');
  });
}

get_all_apid_for_npl = () => {
  let L_var = {
    'token': this.props.tokenNPL,
    'ticket': this.props.ticket,
    'shard': this.props.shard,
    'query': this.props.RequeteInfosActif.requete,
    'similarite': this.props.RequeteInfosActif.requete_avancee.SIMILARITE,
    'Ordre': Constante.RequeteOrdre.Relevance,
    'NBResultat': 200,
    'page': 1,
    'emplacement': this.props.RechercheInfo.EmplacementRecherche1,
    'idrecherche': this.props.RechercheInfo.IdRecherche,
    'selection': this.state.DocumentsInfo["Recherche1"].DocumentsSelectREP,
    'reduit': 'APID'
  };
  AjaxGestion(this.state.DocumentsInfo["Recherche1"].AjaxRecherche, L_var).then((data) => {
    if (data.erreur !== null && typeof data.erreur !== 'undefined') {
      this.reset_documents();
      return;
    }
    let L_apid_tab = [];
    for (let L_parcours_doc = 0; L_parcours_doc < data.documents.length; L_parcours_doc++) {
      L_apid_tab.push(data.documents[L_parcours_doc].APID);
    }
    this.get_concept_top(L_apid_tab, false);
  });
}

get_concept_top(P_list_apid, P_async_calcul) {
  let CodeChangeRequestAdvance = Constante.RequeteAvancee.ConceptNPLUpdate;
  this.calculConceptLaunch = true;
  if (P_async_calcul) {
    CodeChangeRequestAdvance = Constante.RequeteAvancee.ConceptNPLUpdatePrevious;
  }
  let L_query = '';
  if (P_list_apid !== null) {
    let L_query_build = '';
    for (let L_parcours_list = 0; L_parcours_list < P_list_apid.length; L_parcours_list++) {
      L_query_build += P_list_apid[L_parcours_list];
      if (L_query_build.length > 9900) {
        //L_query+= "..MEMADD TOTO "+L_query_build+";";
        //L_query_build=' or ';
        break;
      }
      else {
        L_query_build += ' OR ';
      }
    }
    /*if (L_query_build.length>5){
      L_query+= "..MEMADD TOTO "+L_query_build+";";
    }
    if (L_query.length>0){
      L_query= '..ER MEM TOTO;'+ L_query + '(*MEM TOTO )/APID;';
    }*/
    if (L_query_build.length > 5) {
      L_query = " (" + L_query_build.substring(0, L_query_build.length - 4) + " )/APID";
    }
  }
  else {
    L_query = this.props.RequeteInfosActif.requete;
  }

  if (L_query.length < 1) {
    this.reset_documents(null);
    return;
  }
  let L_var = {
    'token': this.props.tokenNPL,
    'ticket': this.props.ticket,
    'query': L_query,
    'shard': this.props.shard
  };
  AjaxGestion(Constante.AjaxChoix.GetConceptLastSearch, L_var).then((data) => {
    this.calculConceptLaunch = false;
    if (data.erreur !== null && typeof data.erreur !== 'undefined') {
      this.reset_documents();
      return;
    }
    let L_requete = '';
    if (data.stats !== null && typeof data.stats !== 'undefined') {
      if (data.stats.length > 0) {
        if (data.stats[0].responseBlocs !== null && typeof data.stats[0].responseBlocs !== 'undefined') {
          if (data.stats[0].responseBlocs.length > 0) {
            if (data.stats[0].responseBlocs[0].statsEntries !== null && typeof data.stats[0].responseBlocs[0].statsEntries !== 'undefined') {
              for (let L_parcours_stats = 0; L_parcours_stats < data.stats[0].responseBlocs[0].statsEntries.length; L_parcours_stats++) {
                L_requete += data.stats[0].responseBlocs[0].statsEntries[L_parcours_stats].c[0].trim().replace(/  +/, ' ').replace(' ', ' 1D ') + ' OR ';
              }
            }
          }
        }
      }
    }
    if (L_requete.length > 0) {
      L_requete = L_requete.substring(0, L_requete.length - 4);
      this.props.changement_requete_avancee(CodeChangeRequestAdvance, { concept: L_requete });
    }
    else {
      this.reset_documents(null);
    }
  });
}

getSearch = (P_choix, P_document_info, P_reset_select, P_avance_page_seulement, P_new_search) => {

  //permet de saovir si on a un terme de recherche (utile pour la NPL)
  let L_terme_and_trouve = false;
  if (this.props.RequeteInfosActif.requete_avancee.TERMES_AND.LIST_ELEM.length > 0) {
    if (this.props.RequeteInfosActif.requete_avancee.TERMES_AND.LIST_ELEM[0][0].length > 0) {
      L_terme_and_trouve = true;
    }
  }

  if (this.props.RequeteInfosActif === null) {
    return;
  }
  let L_documentsInfo;
  if (P_choix === null || typeof P_choix === 'undefined') {
    P_choix = this.props.RechercheInfo.Choix;
  }
  else {
    if (P_choix !== '2') {
      P_choix = '1';
    }
  }
  if (typeof P_document_info === 'undefined' || P_document_info === null) {
    L_documentsInfo = Object.assign({}, this.state.DocumentsInfo);
    L_documentsInfo['Recherche' + P_choix].Documents = [];
    L_documentsInfo['Recherche' + P_choix].DocumentsSelect = [];
    L_documentsInfo['Recherche' + P_choix].DocumentsSelectREP = [];
    L_documentsInfo['Recherche' + P_choix].NBDocs = 0;
    L_documentsInfo['Recherche' + P_choix].PageEnCours = 1;
  }
  else {
    L_documentsInfo = P_document_info;

  }
  if (P_new_search) {
    L_documentsInfo = this.reset_documents_all(L_documentsInfo);
  }
  if (P_avance_page_seulement === null || typeof P_avance_page_seulement === 'undefined') {
    P_avance_page_seulement = 0;
  }
  if (P_avance_page_seulement === 0) {
    if (this.props.RechercheInfo['EmplacementRecherche' + P_choix] === Constante.EmplacementRecherche.FAMPAT) {
      this.changeSelectOuMode(Constante.AffichageSelect.graphe, Constante.affichageMode.rechercheavancee);
    }
    else {
      let L_mode_affichage = null;
      //si on est en mode Recherche avancee on passe en mode affichage normal
      if (this.state.affichage.mode === Constante.affichageMode.Recherche) {
        L_mode_affichage = Constante.affichageMode.rechercheavancee;
      }
      this.changeSelectOuMode(Constante.AffichageSelect.document, L_mode_affichage);
    }
  }
  this.reset_document_un();

  if (P_reset_select) {
    L_documentsInfo['Recherche' + P_choix].Documents = [];
    L_documentsInfo['Recherche' + P_choix].DocumentsSelect = [];
    L_documentsInfo['Recherche' + P_choix].DocumentsSelectREP = [];
    L_documentsInfo['Recherche' + P_choix].NBDocs = 0;
    L_documentsInfo['Recherche' + P_choix].PageEnCours = 1;
  }

  if (this.props.RequeteInfosActif.requete !== '' ||
    (this.props.RechercheInfo['EmplacementRecherche' + P_choix] !== Constante.EmplacementRecherche.FAMPAT)) {
    L_documentsInfo['Recherche' + P_choix].etat = Constante.etat.encours;
    this.setState({
      DocumentsInfo: L_documentsInfo
    }, () => {
      let L_requete = this.props.RequeteInfosActif.requete;
      let L_date = [null, null];
      if (P_choix === "1" && this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.FAMPAT) {
        if ((this.props.RequeteInfosActif.requete === "" && this.props.RequeteInfosActif.is_recherche_avancee === false) ||
          (L_terme_and_trouve === false && this.props.RequeteInfosActif.is_recherche_avancee === true)) {
          this.get_concept_top(null, true);
        }
      }
      if (this.props.RechercheInfo['EmplacementRecherche' + P_choix] === Constante.EmplacementRecherche.NPL) {
        for (let L_parcours_other = 0; L_parcours_other < this.props.RequeteInfosActif.requete_avancee.OTHERS.length; L_parcours_other++) {
          if (this.props.RequeteInfosActif.requete_avancee.OTHERS[L_parcours_other].CODE === Constante.RequeteAvanceeCode.DATES) {
            L_date = this.props.RequeteInfosActif.requete_avancee.OTHERS[L_parcours_other].LIST_ELEM;
          }
        }

        if (this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SIMILARITY ||
          this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.PRIOR_ART ||
          this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SEMANTIQUE ||
          this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.LIST ||
          this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.WORKFILE ||
          this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.ALERT ||
          (this.props.RequeteInfosActif.requete === "" && this.props.RequeteInfosActif.is_recherche_avancee === false) ||
          (L_terme_and_trouve === false && this.props.RequeteInfosActif.is_recherche_avancee === true)) {
          if (this.props.RequeteInfosActif.requete_avancee.NPLConcept === "") { // si on a pas encore recup les concept de cette recherche pour la recherche NPL
            if (this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SIMILARITY ||
              this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.PRIOR_ART ||
              this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SEMANTIQUE ||
              this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.LIST ||
              this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.WORKFILE ||
              this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.ALERT) {
              this.get_all_apid_for_npl();
            }
            else {
              this.get_concept_top(null, false);
            }
            return;
          }
          /*else {
            L_requete = this.props.RequeteInfosActif.requete_avancee.NPLConcept;
          }*/
        }
        L_requete = this.props.RequeteInfosActif.requete_npl;
        if (this.props.RequeteInfosActif.requete_avancee.NPLConcept.length > 1) {
          if (L_requete.length > 0) {
            L_requete = '(' + this.props.RequeteInfosActif.requete_avancee.NPLConcept + ') AND (' + L_requete + ')';
          }
          else {
            L_requete = this.props.RequeteInfosActif.requete_avancee.NPLConcept;
          }
        }
      }

      let L_var = {
        token: this.props.tokenNPL,
        ticket: this.props.ticket,
        shard: this.props.shard,
        query: this.props.RequeteInfosActif.requete,
        query_npl: this.props.RequeteInfosActif.requete_npl,
        concept: this.props.RequeteInfosActif.requete_avancee.NPLConcept,
        similarite: this.props.RequeteInfosActif.requete_avancee.SIMILARITE,
        Ordre: this.state.DocumentsInfo.Ordre,
        NBResultat: this.state.DocumentsInfo.NBResultat,
        page: this.state.DocumentsInfo['Recherche' + P_choix].PageEnCours,
        date: L_date,
        emplacement: this.props.RechercheInfo['EmplacementRecherche' + P_choix],
        idrecherche: this.props.RechercheInfo.IdRecherche,
        selection: this.state.DocumentsInfo['Recherche' + P_choix].DocumentsSelectREP
      };
      AjaxGestion(this.state.DocumentsInfo['Recherche' + P_choix].AjaxRecherche, L_var).then((data) => {
        if (data.erreur !== null && typeof data.erreur !== 'undefined') {
          this.reset_documents();
          return;
        }

        let L_documentsInfo = Object.assign({}, this.state.DocumentsInfo);

        // maj des stats
        if (this.props.RequeteInfosActif.requete !== '' &&
          this.props.RechercheInfo['EmplacementRecherche' + P_choix] === Constante.EmplacementRecherche.FAMPAT &&
          this.props.user.acces.graphe === true && L_documentsInfo['Recherche' + P_choix].PageEnCours === 1) {
          this.graph.current.updateGraph();
        }

        if (this.props.RechercheInfo['EmplacementRecherche' + P_choix] === Constante.EmplacementRecherche.NPL) {
          if (data.listOfDocument !== null && typeof data.listOfDocument !== 'undefined') {
            data.nb = data.metadata.total;
            L_documentsInfo['Recherche' + P_choix].NBDocs = data.metadata.total;
            L_documentsInfo['Recherche' + P_choix].NBPages = Math.ceil(L_documentsInfo['Recherche' + P_choix].NBDocs / L_documentsInfo.NBResultat);
            var L_count_NPL = L_documentsInfo['Recherche' + P_choix].PageEnCours * L_documentsInfo.NBResultat - L_documentsInfo.NBResultat + 1;
            L_documentsInfo['Recherche' + P_choix].Documents = this.clearDocNPL(data.listOfDocument, L_count_NPL);
            if (this.state.affichage.graphe === 1) {
              this.changeSelectOuMode(Constante.AffichageSelect.document, null);
            }
          }
          else {
            this.reset_documents();
            return;
          }
        }
        else {
          if (data.nb !== null && typeof data.nb !== 'undefined') {
            L_documentsInfo['Recherche' + P_choix].Documents = this.clearDoc(data.documents);
            L_documentsInfo['Recherche' + P_choix].NBDocs = data.nb;
            L_documentsInfo['Recherche' + P_choix].NBPages = Math.ceil(L_documentsInfo['Recherche' + P_choix].NBDocs / L_documentsInfo.NBResultat);
            var L_count = L_documentsInfo['Recherche' + P_choix].PageEnCours * L_documentsInfo.NBResultat - L_documentsInfo.NBResultat + 1;
            for (let L_parcours_documents = 0; L_parcours_documents < L_documentsInfo['Recherche' + P_choix].Documents.length; L_parcours_documents++) {
              L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].ETOILE = parseInt(L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].STAR);
              L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].POSITION = L_count;
              L_count++;
              if (L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].IMG?.length) {
                L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].IMAGE = L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].IMG[0];
              }
              else {
                L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].IMAGE = './images/aucune.svg';
              }
              L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].DATE = "";
              if (this.props.RechercheInfo['EmplacementRecherche' + P_choix] !== Constante.EmplacementRecherche.WORKFILE) {
                L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].ETOILE = -1;
              }
              if (isNaN(L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].ETOILE)) {
                L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].ETOILE = -1;
              }
              if (L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].PN !== null &&
                typeof L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].PN !== "undefined") {
                let L_pn_split = L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].PN.replace(/ +/g, ' ').split(' ');
                if (L_pn_split.length > 3) {
                  L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].DATE = L_pn_split[2];
                }
              }
              if (L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].TI === null ||
                typeof L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].TI === "undefined") {
                L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].TI = '';
              }
              if (L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].AB === null ||
                typeof L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].AB === "undefined") {
                L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].AB = '';
              }
              else {
                L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].AB = L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].AB.replace(/\(.*\)<br\/>/, '');
              }
              if (L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].PA === null ||
              typeof L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].PA === "undefined") {
                L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].PA = '';
              }
              else {
                L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].PA = L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].PA.split('<br/>')[0];
              }
              if (L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].REP === null ||
                typeof L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].REP === "undefined") {
                L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].REP = '';
              }
              else {
                L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].REP = L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].REP.replace('_', ' ');
              }
              if (L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].EPRD === null ||
                typeof L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].EPRD === "undefined") {
                L_documentsInfo['Recherche' + P_choix].Documents[L_parcours_documents].EPRD = '';
              }
            }

          }
        }
        if (L_documentsInfo['Recherche' + P_choix].NBDocs > Constante.RequeteOrdre.max && this.props.RechercheInfo['EmplacementRecherche' + P_choix] !== Constante.EmplacementRecherche.NPL) {
          L_documentsInfo.Ordre = Constante.RequeteOrdre.Relevance;
        }
        L_documentsInfo['Recherche' + P_choix].etat = Constante.etat.charge;

        this.setState({
          DocumentsInfo: L_documentsInfo
        });
        let L_requete_termes = "";
        let isExecutedQuery = false;
        if (data.executedQuery != null && typeof data.executedQuery !== 'undefined') {
          isExecutedQuery = true;
          if (this.props.RechercheInfo['EmplacementRecherche' + P_choix] === Constante.EmplacementRecherche.LIST ||
            this.props.RechercheInfo['EmplacementRecherche' + P_choix] === Constante.EmplacementRecherche.WORKFILE) {
            L_requete_termes = this.props.RequeteInfosActif.requete;
          }
          else {
            L_requete_termes = data.executedQuery;
          }
        }
        if (L_documentsInfo['Recherche' + P_choix].NBDocs > 0 && window.getComputedStyle(document.getElementById('D_droite')).display !== 'none') {
          if (P_avance_page_seulement < 0) {
            this.voirDoc(L_documentsInfo.NBResultat * L_documentsInfo['Recherche' + P_choix].PageEnCours, null, null);
          }
          else {
            this.voirDoc(1 + (L_documentsInfo.NBResultat * (L_documentsInfo['Recherche' + P_choix].PageEnCours - 1)), null, null);
          }
        }
        this.props.RequeteInfosSave(data.nb, L_requete_termes, isExecutedQuery, this.histo_save);
        this.histo_save = true;
      });
    });
  }
}

getPermalink = () => {
  this.reset_document_un();
  console.log("getPermalink:" + this.props.xpn);
  this.changeSelectOuMode(Constante.AffichageSelect.documentEtVisioneuse, Constante.affichageMode.listedoc);
  this.setState({
    documentUn: {
      data: {},
      etat: Constante.etat.encours
    }
  });
  if (this.props.xpn !== '') {
    let L_var = {
      'xpn': this.props.xpn,
      'base': this.props.base,
    };
    let l_error = 0;
    AjaxGestion(Constante.AjaxChoix.permalink, L_var).then((data) => {
      if (data.erreur !== null && typeof data.erreur !== 'undefined') {
        console.log("Error:" + data.erreur);
        l_error = 1;
      }
      if (data.data.message !== null && data.data.message === 'ko') {
        console.log("Error:" + data.message);
        l_error = 1;
      }
      if (l_error === 1) {
        this.setState({
          documentUn: {
            data: {},
            etat: Constante.etat.erreur
          }
        });
        return;
      }
      let L_documentsUn = null;
      if (data.data.nb !== null) {
        data.data.documents[0].PosImage = -1;
        data.data.documents[0].PatentCitation = [];
        data.data.documents[0].PatentCiting = [];
        data.data.documents[0].Comment = [];
        data.data.documents[0].CP = [];
        data.data.documents[0].CitedBy = [];
        if (typeof data.data.documents[0].IMG !== 'undefined') {
          data.data.documents[0].PosImage = 0;
        }

        L_documentsUn = Object.assign({}, this.state.documentUn);
        L_documentsUn.data = data.data.documents[0];

        L_documentsUn.data.IMAGE = "";
        L_documentsUn.data.ETOILE = parseInt(L_documentsUn.data.STAR);
        if (isNaN(L_documentsUn.data.ETOILE)) {
          L_documentsUn.data.ETOILE = -1;
        }
        if (L_documentsUn.data.IMG?.length) {
          L_documentsUn.data.IMAGE = L_documentsUn.data.IMG[0];
        }
        else {
          L_documentsUn.data.IMG = [];
        }
        //let L_v_fact = L_documentsUn.data.V_FACT;
        if (L_documentsUn.data.V_FACT === null || typeof L_documentsUn.data.V_FACT === 'undefined') {
          L_documentsUn.data.V_FACT = { STATUS: null, STATE: null };
        }
        if (L_documentsUn.data.V_APL === null || typeof L_documentsUn.data.V_APL === 'undefined') {
          L_documentsUn.data.V_APL = [];
        }
        if (typeof L_documentsUn.data.UFLD === 'undefined') {
          L_documentsUn.data.UFLD = {};
        }
        if (L_documentsUn.data.ADB === null || typeof L_documentsUn.data.ADB === 'undefined') {
          L_documentsUn.data.ADB = '';
        }
        if (L_documentsUn.data.EPRD === null) {
          L_documentsUn.data.EPRD = '';
          if (L_documentsUn.data.PRD !== null && typeof L_documentsUn.data.PRD != 'undefined') {
            L_documentsUn.data.EPRD = L_documentsUn.data.PRD.split('<br/>')[0];
          }
        }
        if (L_documentsUn.data.REP === null) {
          L_documentsUn.data.REP = '';
        }
        if (L_documentsUn.data.DESC === null) {
          L_documentsUn.data.DESC = '';
        }
        if (L_documentsUn.data.TI === null) {
          L_documentsUn.data.TI = '';
        }
        if (L_documentsUn.data.AB === null) {
          L_documentsUn.data.AB = '';
        }
        if (L_documentsUn.data.ECLM === null || typeof L_documentsUn.data.ECLM === 'undefined') {
          L_documentsUn.data.ECLM = '';
        }
        if (L_documentsUn.data.CLMS === null || typeof L_documentsUn.data.CLMS === 'undefined') {
          L_documentsUn.data.CLMS = '';
        }
        if (L_documentsUn.data.ICLM === null || typeof L_documentsUn.data.ICLM === 'undefined') {
          L_documentsUn.data.ICLM = '';
        }
        if (L_documentsUn.data.OBJ === null || typeof L_documentsUn.data.OBJ === 'undefined') {
          L_documentsUn.data.OBJ = '';
        }
        if (L_documentsUn.data.ODES === null || typeof L_documentsUn.data.ODES === 'undefined') {
          L_documentsUn.data.ODES = '';
        }
        if (L_documentsUn.data.IN !== null) {
          L_documentsUn.data.IN_S = L_documentsUn.data.IN.split(';');
        }
        else {
          L_documentsUn.data.IN_S = [];
        }
        if (L_documentsUn.data.PA !== null) {
          L_documentsUn.data.PA_S = L_documentsUn.data.PA.split('<br/>')
        }
        else {
          L_documentsUn.data.PA_S = [];
        }
        if (L_documentsUn.data.IC !== null) {
          L_documentsUn.data.IC_S = L_documentsUn.data.IC.split('<br/>');
        }
        else {
          L_documentsUn.data.IC_S = [];
        }
        if (L_documentsUn.data.CPC !== null && typeof L_documentsUn.data.CPC !== 'undefined') {
          L_documentsUn.data.CPC_S = L_documentsUn.data.CPC.split('<br/>');
        }
        else {
          L_documentsUn.data.CPC_S = [];
        }
        if (L_documentsUn.data.CTN === null) {
          L_documentsUn.data.CTN = '';
        }
        if (L_documentsUn.data.V_APL === null) {
          L_documentsUn.data.V_APL = [];
        }
        L_documentsUn.data.FPN_CODE = {};
        if (L_documentsUn.data.V_PTCC !== null && typeof L_documentsUn.data.V_PTCC !== 'undefined') {
          if (L_documentsUn.data.V_PTCC.length === 2) {
            for (let L_parcours_ptcc = 0; L_parcours_ptcc < L_documentsUn.data.V_PTCC[0].GRANTED.length; L_parcours_ptcc++) {
              L_documentsUn.data.FPN_CODE[L_documentsUn.data.V_PTCC[0].GRANTED[L_parcours_ptcc].CC] = L_documentsUn.data.V_PTCC[0].GRANTED[L_parcours_ptcc].GRANTED;
            }
            for (let L_parcours_ptcc = 0; L_parcours_ptcc < L_documentsUn.data.V_PTCC[1].PENDING.length; L_parcours_ptcc++) {
              L_documentsUn.data.FPN_CODE[L_documentsUn.data.V_PTCC[1].PENDING[L_parcours_ptcc].CC] = L_documentsUn.data.V_PTCC[1].PENDING[L_parcours_ptcc].PENDING;
            }
          }
        }

        // citing
        L_documentsUn.data.CITED_BY = 0;
        if (L_documentsUn.data.CTGN !== null) {
          L_documentsUn.data.CITED_BY = L_documentsUn.data.CTGN.length;
        }

      }
      L_documentsUn.etat = Constante.etat.charge
      this.setState({
        documentUn: L_documentsUn
      });
    });
  }
}

/**
 * Trigger a new query on Innovation, then open a tab with the Technology landscape displayed
 *
 * @return void
 */
openInnovationDashboard = () => {
  const { user } = this.props;

  // The user has no right to use Innovation
  if (!user.acces.innovation.read) {
    window.open("https://www.questel.com/innovation-intelligence-software/orbit-innovation/", "_blank");
    return;
  }

  // Prevent to early launch
  if (this.calculConceptLaunch) {
    setTimeout(this.openInnovationDashboard, 2000);
    return;
  }

  const settings = {
    token      : this.props.tokenNPL,
    type       : this.props.RechercheInfo.Choix === "1" ? 'orbitSearch' : 'npl',
    emplacement: this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix],
    idrecherche: this.props.RechercheInfo.IdRecherche,
    query      : this.props.RequeteInfosActif.executedQuery,
    queryNPL   : this.props.RequeteInfosActif.requete_avancee.NPLConcept.length > 1 ? this.props.RequeteInfosActif.requete_avancee.NPLConcept : this.props.RequeteInfosActif.requete,
    selection  : this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelectREP,
    similarite : this.props.RequeteInfosActif.requete_avancee.SIMILARITE,
    semantique : this.props.RequeteInfosActif.requete_avancee.SEMANTIQUE
  };

  AjaxGestion(Constante.AjaxChoix.InnoAnalyse, settings).then((data) => {
    const { ticket } = getSession();
    // The query model has been properly created, open the new tab.
    if (data && data.entity && data.entity.entity && data.entity.entity.id) {
      window.open(process.env.REACT_APP_OINNO_ROOT + "/#/b/" + data.entity.entity.id + "/technology-landscape?ticket=" + ticket, "_blank");
    }
  });
}


NouvelleRecherche = (P_search) => {
  this.rechercheavancee.current.NouvelleRecherche(P_search);
}

noteDoc = (P_note, P_note_en_cours, P_id) => {
  if (!this.props.user.acces.note.write) {
    return;
  }
  if (P_note === P_note_en_cours) {
    P_note = 0;
  }
  let L_var = {
    'ticket': this.props.ticket,
    'shard': this.props.shard,
    'id': P_id,
    'emplacement': this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix],
    'idrecherche': this.props.RechercheInfo.IdRecherche,
    'note': P_note
  };
  AjaxGestion(Constante.AjaxChoix.NoteAdd, L_var).then((data) => {
    if (data.message !== null) {
      if (data.message.toLowerCase() === 'ok') {
        let L_documents = Object.assign({}, this.state.DocumentsInfo);
        let L_documentsUn = Object.assign({}, this.state.documentUn);

        for (let L_parcours_doc = 0; L_parcours_doc < L_documents['Recherche' + this.props.RechercheInfo.Choix].Documents.length; L_parcours_doc++) {
          if (L_documents['Recherche' + this.props.RechercheInfo.Choix].Documents[L_parcours_doc].ID === P_id) {
            L_documents['Recherche' + this.props.RechercheInfo.Choix].Documents[L_parcours_doc].STAR = P_note;
            L_documents['Recherche' + this.props.RechercheInfo.Choix].Documents[L_parcours_doc].ETOILE = P_note;
            break;
          }
        }
        L_documents['Recherche' + this.props.RechercheInfo.Choix].etat = Constante.etat.charge
        if (Object.keys(L_documentsUn.data).length > 0) {
          if (L_documentsUn.data.ID === P_id) {
            L_documentsUn.data.STAR = P_note;
            L_documentsUn.data.ETOILE = P_note;
          }
        }
        L_documentsUn.etat = Constante.etat.charge
        this.setState({
          documentUn: L_documentsUn,
          DocumentsInfo: L_documents
        });
      }
    }
  });
}

pageKeyPress = (event) => {
  let L_dialog_comment = document.getElementsByClassName('D_dialog_comment');
  if (L_dialog_comment.length > 0) {
    if (L_dialog_comment[0].style.display !== 'none') {
      return;
    }
  }
  switch (event.keyCode) {
    case 37:
      this.voirDoc(this.state.documentUn.position - 1, null, null)
      break;
    case 39:
      this.voirDoc(this.state.documentUn.position + 1, null, null)
      break;
    default:
      break;
  }
}

PatentCitationLoad = () => {
  let L_documentsUn = Object.assign({}, this.state.documentUn);
  if (L_documentsUn.data.etatPatentCitation !== Constante.etat.initial) {
    return;
  }
  L_documentsUn.data.etatPatentCitation = Constante.etat.encours;
  this.setState({
    documentUn: L_documentsUn
  });
  let L_query = '';
  let L_count_pn = 0;
  let L_patent_citation = this.state.documentUn.data.CTN.split('<br/>');
  this.CPN = {};
  let L_CPN_pn = '';
  let L_CPN_who = '';
  for (let L_parcours_pc = 0; L_parcours_pc < L_patent_citation.length; L_parcours_pc++) {
    if (L_patent_citation[L_parcours_pc].length < 5) {
      continue;
    }
    if (L_patent_citation[L_parcours_pc][0] === '(') {
      continue;
    }
    if (L_patent_citation[L_parcours_pc].indexOf('WHO=') < 1) {
      continue;
    }
    L_CPN_who = L_patent_citation[L_parcours_pc].split('WHO=')[1].split(' ')[0];
    L_CPN_pn = L_patent_citation[L_parcours_pc].split(' ')[0].replace(/<b>/gi, '').replace(/<\/b>/gi, '');
    this.CPN[L_CPN_pn] = L_CPN_who;
    L_count_pn++;
    L_query += L_patent_citation[L_parcours_pc].split(' ')[0];
    if (L_query.length > 1900) {
      break;
    }
    if (L_parcours_pc !== L_patent_citation.length - 1) L_query += " OR ";
  }

  if (L_query.length < 1) {
    L_documentsUn.data.etatPatentCitation = Constante.etat.charge;
    L_documentsUn.data.PatentCitation = [];
    this.setState({
      documentUn: L_documentsUn
    });
    return;
  }
  L_query = "(" + L_query.replace(/<b>/gi, '').replace(/<\/b>/gi, '') + ")/XPN";
  let L_var = {
    'ticket': this.props.ticket,
    'shard': this.props.shard,
    'query': L_query,
    'Ordre': this.state.DocumentsInfo.Ordre,
    'NBResultat': L_count_pn,
    'emplacement': this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix],
    'idrecherche': this.props.RechercheInfo.IdRecherche
  };
  AjaxGestion(Constante.AjaxChoix.PatentCitation, L_var).then((data) => {
    if (data.erreur !== null && typeof data.erreur !== 'undefined') {
      console.log("Error:" + data.erreur);
      return;
    }
    if (data.nb !== null && data.documents != null) {
      let L_documentsUn = Object.assign({}, this.state.documentUn);
      data.documents.sort((a, b) => (a.EPRD.toLowerCase() < b.EPRD.toLowerCase()) ? 1 : ((b.EPRD.toLowerCase() < a.EPRD.toLowerCase()) ? -1 : 0));
      for (let L_parcours_citation = 0; L_parcours_citation < data.documents.length; L_parcours_citation++) {
        if (this.CPN[data.documents[L_parcours_citation].REP.split('_')[0]] != null) {
          data.documents[L_parcours_citation].CTN = this.CPN[data.documents[L_parcours_citation].REP.split('_')[0]];
        }
        else {
          let L_trouve_cton_in_fpn = false;
          if (data.documents[L_parcours_citation].FPN !== null && typeof data.documents[L_parcours_citation].FPN !== 'undefined') {
            for (let L_parcours_fpn = 0; L_parcours_fpn < data.documents[L_parcours_citation].FPN.length; L_parcours_fpn++) {
              if (this.CPN[data.documents[L_parcours_citation].FPN[L_parcours_fpn].number] != null) {
                data.documents[L_parcours_citation].CTN = this.CPN[data.documents[L_parcours_citation].FPN[L_parcours_fpn].number];
                L_trouve_cton_in_fpn = true;
                break;
              }
            }
          }
          if (!L_trouve_cton_in_fpn) {
            data.documents[L_parcours_citation].CTN = 'UNKNOWN';
          }
        }
      }
      L_documentsUn.data.PatentCitation = data.documents;
      L_documentsUn.data.etatPatentCitation = Constante.etat.charge;
      this.setState({
        documentUn: L_documentsUn
      });
    }
  });
}

PatentCitingLoad = () => {
  let L_documentsUn = Object.assign({}, this.state.documentUn);
  if (L_documentsUn.data.etatPatentCiting !== Constante.etat.initial) {
    return;
  }
  L_documentsUn.data.etatPatentCiting = Constante.etat.encours;
  this.setState({
    documentUn: L_documentsUn
  });
  let L_query = '';
  let L_count_pn = 0;
  let L_patent_citation = this.state.documentUn.data.CTGN.split('<br/>');
  this.CTGN = {};
  let L_CPN_pn = '';
  let L_CPN_who = '';
  for (let L_parcours_pc = 0; L_parcours_pc < L_patent_citation.length; L_parcours_pc++) {
    if (L_patent_citation[L_parcours_pc].length < 20) {
      continue;
    }
    if (L_patent_citation[L_parcours_pc].indexOf('WHO=') < 1) {
      continue;
    }
    L_CPN_who = L_patent_citation[L_parcours_pc].split('WHO=')[1].split(' ')[0];
    L_CPN_pn = L_patent_citation[L_parcours_pc].split(' ')[0].replace(/<b>/gi, '').replace(/<\/b>/gi, '');
    this.CTGN[L_CPN_pn] = L_CPN_who;
    L_count_pn++;
    L_query += L_patent_citation[L_parcours_pc].split(' ')[0];
    if (L_query.length > 1900) {
      break;
    }
    if (L_parcours_pc !== L_patent_citation.length - 1) L_query += " OR ";
  }

  if (L_query.length < 1) {
    L_documentsUn.data.etatPatentCiting = Constante.etat.charge;
    L_documentsUn.data.PatentCiting = [];
    this.setState({
      documentUn: L_documentsUn
    });
    return;
  }
  L_query = "(" + L_query.replace(/<b>/gi, '').replace(/<\/b>/gi, '') + ")/XPN";
  let L_var = {
    'ticket': this.props.ticket,
    'shard': this.props.shard,
    'query': L_query,
    'Ordre': this.state.DocumentsInfo.Ordre,
    'NBResultat': L_count_pn,
    'emplacement': this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix],
    'idrecherche': this.props.RechercheInfo.IdRecherche
  };
  AjaxGestion(Constante.AjaxChoix.PatentCitation, L_var).then((data) => {
    if (data.erreur !== null && typeof data.erreur !== 'undefined') {
      console.log("Error:" + data.erreur);
      return;
    }
    if (data.nb !== null && data.documents != null) {
      let L_documentsUn = Object.assign({}, this.state.documentUn);
      data.documents.sort((a, b) => (a.EPRD < b.EPRD) ? 1 : ((b.EPRD < a.EPRD) ? -1 : 0));
      for (let L_parcours_citation = 0; L_parcours_citation < data.documents.length; L_parcours_citation++) {
        if (this.CTGN[data.documents[L_parcours_citation].REP.split('_')[0]] != null) {
          data.documents[L_parcours_citation].CTGN = this.CTGN[data.documents[L_parcours_citation].REP.split('_')[0]];
        }
        else {
          let L_trouve_fpn_in_fpn = false;
          if (data.documents[L_parcours_citation].FPN !== null && typeof data.documents[L_parcours_citation].FPN !== 'undefined') {
            for (let L_parcours_fpn = 0; L_parcours_fpn < data.documents[L_parcours_citation].FPN.length; L_parcours_fpn++) {
              if (this.CTGN[data.documents[L_parcours_citation].FPN[L_parcours_fpn].number] != null) {
                data.documents[L_parcours_citation].CTGN = this.CTGN[data.documents[L_parcours_citation].FPN[L_parcours_fpn].number];
                L_trouve_fpn_in_fpn = true;
                break;
              }
            }
          }
          if (!L_trouve_fpn_in_fpn) {
            data.documents[L_parcours_citation].CTGN = 'UNKNOWN';
          }
        }
      }
      L_documentsUn.data.PatentCiting = data.documents;
      L_documentsUn.data.etatPatentCiting = Constante.etat.charge;

      L_documentsUn.data.CITED_BY = 0;
      if (L_documentsUn.data.CTGN !== null) {
        L_documentsUn.data.CITED_BY = L_documentsUn.data.CTGN.length;
      }

      this.setState({
        documentUn: L_documentsUn
      });
    }
  });
}

reset_documents = () => {
  let L_documents = Object.assign({}, this.state.DocumentsInfo);
  if (this.props.RechercheInfo.Choix === "2") {
    L_documents['Recherche' + this.props.RechercheInfo.Choix].AjaxRecherche = Constante.AjaxChoix.listeDocNPL;
  }
  else {
    L_documents['Recherche' + this.props.RechercheInfo.Choix].AjaxRecherche = Constante.AjaxChoix.listeDoc;
  }
  L_documents.Ordre = Constante.RequeteOrdre.Relevance;
  L_documents['Recherche' + this.props.RechercheInfo.Choix].Documents = [];
  L_documents['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect = [];
  L_documents['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelectREP = [];
  L_documents['Recherche' + this.props.RechercheInfo.Choix].NBDocs = 0;
  L_documents['Recherche' + this.props.RechercheInfo.Choix].NBPages = 0;
  L_documents['Recherche' + this.props.RechercheInfo.Choix].PageEnCours = 1;
  L_documents['Recherche' + this.props.RechercheInfo.Choix].etat = Constante.etat.initial; // permet de connaitre l'état de Recherche
  this.setState({
    DocumentsInfo: L_documents
  });
  this.reset_document_un();
}


reset_documents_all = (P_document_info) => {
  let L_documents = null;
  if (P_document_info !== null) {
    L_documents = Object.assign({}, P_document_info);
  }
  else {
    L_documents = Object.assign({}, this.state.DocumentsInfo);
  }
  var L_choix = "Recherche1";
  L_documents[L_choix].AjaxRecherche = Constante.AjaxChoix.listeDoc;
  L_documents[L_choix].Documents = [];
  L_documents[L_choix].DocumentsSelect = [];
  L_documents[L_choix].DocumentsSelectREP = [];
  L_documents[L_choix].NBDocs = 0;
  L_documents[L_choix].NBPages = 0;
  L_documents[L_choix].PageEnCours = 1;
  L_documents[L_choix].etat = Constante.etat.initial; // permet de connaitre l'état de Recherche

  L_choix = "Recherche2";
  L_documents[L_choix].AjaxRecherche = Constante.AjaxChoix.listeDocNPL;
  L_documents[L_choix].Documents = [];
  L_documents[L_choix].DocumentsSelect = [];
  L_documents[L_choix].DocumentsSelectREP = [];
  L_documents[L_choix].NBDocs = 0;
  L_documents[L_choix].NBPages = 0;
  L_documents[L_choix].PageEnCours = 1;
  L_documents[L_choix].etat = Constante.etat.initial; // permet de connaitre l'état de Recherche

  if (P_document_info === null) {
    this.setState({
      DocumentsInfo: L_documents
    });
  }
  return L_documents;
}

reset_document_un = () => {
  let L_documentsUn = Object.assign({}, this.state.documentUn);
  L_documentsUn.data = {};
  L_documentsUn.etat = Constante.etat.pret
  L_documentsUn.position = 0;
  this.setState({
    documentUn: L_documentsUn
  });
}

search_change_mode_patent_npl = (P_choix) => {
  if (this.props.RechercheInfo.Choix === P_choix.toString()) {
    return;
  }
  this.setState({
    documentUn: {
      data: {},
      etat: Constante.etat.initial,
      position: 0
    }
  }, () => {
    let L_relance = false;
    if (this.state.DocumentsInfo['Recherche' + P_choix].etat !== Constante.etat.charge) {
      L_relance = true;
    }
    if (P_choix === "2" && this.state.affichage.document !== 1) {
      this.changeSelectOuMode(Constante.AffichageSelect.document, null);
    }
    this.props.set_emplacement_recherche(P_choix, null, null, null, null, L_relance);
  });
}

activeDoc = (P_APID) => {
  let L_APID;
  if (P_APID !== null) {
    L_APID = P_APID;
  }
  else {
    L_APID = this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentActif;
  }
  let L_elem = document.getElementById('D_liste_doc_liste_doc_' + L_APID);
  if (L_elem !== null) {
    let L_rect = L_elem.getBoundingClientRect();
    let L_viewHeight = document.getElementById("D_liste_doc_liste").clientHeight;
    if (L_rect.top < 0 || L_rect.top - L_viewHeight >= 0) {
      L_elem.scrollIntoView(true);
    }
  }
  if (P_APID !== null) {
    let L_documentsInfo = Object.assign({}, this.state.DocumentsInfo);
    L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentActif = P_APID;
    this.setState({
      DocumentsInfo: L_documentsInfo
    });
  }
}

search_prec = (P_num_historique_fin) => {
  this.histo_save = false;
  this.props.requeteHistoryAction(1, this.props.RequeteInfosListe.length - P_num_historique_fin, true);
}

selectDoc = (P_APID, P_REP, P_reset, P_all_page) => {
  let L_documentsInfo = Object.assign({}, this.state.DocumentsInfo);
  if (P_reset) {
    L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect = [];
    L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelectREP = [];
    L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentActif = 0;
  }
  else if (P_all_page) {
    for (let L_parcours_select = 0; L_parcours_select < this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].Documents.length; L_parcours_select++) {
      let L_deja_present = false;
      for (let L_parcours_deja_select = 0; L_parcours_deja_select < L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect.length; L_parcours_deja_select++) {
        if (this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].Documents[L_parcours_select].APID === L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect[L_parcours_deja_select]) {
          L_deja_present = true;
          break;
        }
      }
      if (L_deja_present === false) {
        L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect.push(this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].Documents[L_parcours_select].APID);
        L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelectREP.push(this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].Documents[L_parcours_select].REP);
      }
    }
  }
  else {
    let L_trouve = false;
    for (let L_parcours_select = 0; L_parcours_select < L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect.length; L_parcours_select++) {
      if (L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect[L_parcours_select] === P_APID) {
        L_trouve = true;
        L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect.splice(L_parcours_select, 1);
        L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelectREP.splice(L_parcours_select, 1);
        break;
      }
    }
    if (!L_trouve) {
      L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect.push(P_APID);
      L_documentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelectREP.push(P_REP);
    }
  }
  this.setState({
    DocumentsInfo: L_documentsInfo
  });
}

similariteSearchUnDoc = () => {
  this.changeSelectOuMode(null, Constante.affichageMode.rechercheavancee);
  //this.changeDroiteRechercheAvanceeClose();
  this.props.changement_requete_avancee(Constante.RequeteAvancee.ModeSimilariteUn, this.state.documentUn);
}


priorArtSearchUnDoc = () => {
  this.changeSelectOuMode(null, Constante.affichageMode.rechercheavancee);
  //this.changeDroiteRechercheAvanceeClose();
  this.props.changement_requete_avancee(Constante.RequeteAvancee.ModePriorArt, this.state.documentUn);
}



similariteSearch = () => {
  //this.changeDroiteRechercheAvanceeClose();
  this.props.changement_requete_avancee(Constante.RequeteAvancee.ModeSimilarite, this.state.DocumentsInfo);
}

voirDoc = (P_position, P_select_affichage, P_mode_affichage) => {
  if (window.getComputedStyle(document.getElementById('D_droite')).display === 'none') {
    P_mode_affichage = Constante.affichageMode.listedoc;
  }
  this.changeSelectOuMode(P_select_affichage, P_mode_affichage);
  if (P_position === null) {
    P_position = 1 + (this.state.DocumentsInfo.NBResultat * (this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours - 1));
  }
  if (P_position < 1) {
    P_position = 1;
  }
  else if (P_position > this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBDocs) {
    P_position = this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].Documents.length;
  }

  if (P_position > this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours * this.state.DocumentsInfo.NBResultat) {
    this.changePage(1);
    return;
  }

  if (P_position <= (this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours - 1) * this.state.DocumentsInfo.NBResultat) {
    this.changePage(-1);
    return;
  }
  let L_docid = '';
  if (this.props.RechercheInfo.Choix === "1") {
    L_docid = this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].Documents[P_position - 1 -
      (this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours - 1) * this.state.DocumentsInfo.NBResultat].APID;
  }
  else {
    if (this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.NPL) {
      let L_documentsUnNPL = Object.assign({}, this.state.documentUn);
      L_documentsUnNPL.data = JSON.parse(JSON.stringify(this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].Documents[P_position - 1 -
        (this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours - 1) * this.state.DocumentsInfo.NBResultat]));
      // changement document actif
      if (L_documentsUnNPL.data.id !== this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentActif) {
        this.activeDoc(L_documentsUnNPL.data.id);
      }
      L_documentsUnNPL.etat = Constante.etat.charge;
      L_documentsUnNPL.position = P_position;
      this.setState({
        documentUn: L_documentsUnNPL
      });
    }
    return;
  }

  this.setState({
    documentUn: {
      data: {},
      etat: Constante.etat.encours,
      position: P_position
    }
  });

  if (this.props.RequeteInfosActif.requete !== '' || this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] !== Constante.EmplacementRecherche.FAMPAT) {
    let L_requete = this.props.RequeteInfosActif.requete;
    let L_date = [null, null];
    if (this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.NPL) {
      for (let L_parcours_other = 0; L_parcours_other < this.props.RequeteInfosActif.requete_avancee.OTHERS.length; L_parcours_other++) {
        if (this.props.RequeteInfosActif.requete_avancee.OTHERS[L_parcours_other].CODE === Constante.RequeteAvanceeCode.DATES) {
          L_date = this.props.RequeteInfosActif.requete_avancee.OTHERS[L_parcours_other].LIST_ELEM;
        }
      }
      L_requete = this.props.RequeteInfosActif.requete_npl;
      if (this.props.RequeteInfosActif.requete_avancee.NPLConcept.length > 1) {
        if (L_requete.length > 0) {
          L_requete = '(' + this.props.RequeteInfosActif.requete_avancee.NPLConcept + ') AND (' + L_requete + ')';
        }
        else {
          L_requete = this.props.RequeteInfosActif.requete_avancee.NPLConcept;
        }
      }
    }
    let L_var = {
      'token': this.props.tokenNPL,
      'ticket': this.props.ticket,
      'shard': this.props.shard,
      'query': L_requete,
      'date': L_date,
      'similarite': this.props.RequeteInfosActif.requete_avancee.SIMILARITE,
      'Ordre': this.state.DocumentsInfo.Ordre,
      'NBResultat': 1,
      'position': P_position,
      'docID': L_docid,
      'emplacement': this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix],
      'idrecherche': this.props.RechercheInfo.IdRecherche
    };
    AjaxGestion(Constante.AjaxChoix.unDoc, L_var).then((data) => {

      let L_documentsUn = null;

      if (data.erreur !== null && typeof data.erreur !== 'undefined') {
        this.setState({
          documentUn: {
            data: {},
            etat: Constante.etat.initial,
            position: 0
          }
        });
        console.log("Error:" + data.erreur);
        return;
      }
      if (data.nb !== null) {
        data.documents[0].PosImage = -1;
        data.documents[0].PatentCitation = [];
        data.documents[0].PatentCiting = [];
        data.documents[0].Comment = [];
        data.documents[0].CP = [];
        data.documents[0].CitedBy = [];
        if (typeof data.documents[0].IMG !== 'undefined') {
          data.documents[0].PosImage = 0;
        }

        let L_cp = [];
        if (data.fields !== null) {
          for (let L_parcours_fields = 0; L_parcours_fields < data.fields.length; L_parcours_fields++) {
            if (data.fields[L_parcours_fields].name === 'UFLD') {
              L_cp = [];
              for (let L_parcours_valeur = 0; L_parcours_valeur < data.fields[L_parcours_fields].subFields.length; L_parcours_valeur++) {
                L_cp.push(data.fields[L_parcours_fields].subFields[L_parcours_valeur]);
                if (L_cp[L_cp.length - 1].type === 'TXT') {
                  L_cp[L_cp.length - 1].Ref = React.createRef();
                  L_cp[L_cp.length - 1].EditMode = false;
                  L_cp[L_cp.length - 1].OptionMode = false;
                  L_cp[L_cp.length - 1].data = null;
                  //L_cp[L_cp.length - 1].data = [{ value: '', valueDefault: '', select: false }];
                }
                else if (L_cp[L_cp.length - 1].type === 'DAT') {
                  L_cp[L_cp.length - 1].Ref = React.createRef();
                  L_cp[L_cp.length - 1].Open = false;
                }
                else {
                  L_cp[L_cp.length - 1].Open = false;
                }
                //L_cp[L_cp.length - 1].VALEUR = [];
                let L_valeur_suite = "";
                if (data.documents[0].UFLD !== null && typeof data.documents[0].UFLD !== 'undefined') {
                  if (data.documents[0].UFLD[data.fields[L_parcours_fields].subFields[L_parcours_valeur].name] !== null &&
                    typeof data.documents[0].UFLD[data.fields[L_parcours_fields].subFields[L_parcours_valeur].name] !== 'undefined') {
                    //L_cp[L_cp.length - 1].VALEUR = data.documents[0].UFLD[data.fields[L_parcours_fields].subFields[L_parcours_valeur].name].split(";");
                    L_valeur_suite = ';' + data.documents[0].UFLD[data.fields[L_parcours_fields].subFields[L_parcours_valeur].name] + ';';
                  }
                }
                if (L_cp[L_cp.length - 1].data === null || typeof L_cp[L_cp.length - 1].data == "undefined") {
                  if (L_valeur_suite.length > 2) {
                    L_cp[L_cp.length - 1].data = [{
                      value: L_valeur_suite.substring(1, L_valeur_suite.length - 1),
                      valueDefault: L_valeur_suite.substring(1, L_valeur_suite.length - 1),
                      select: true
                    }];
                  }
                  else {
                    L_cp[L_cp.length - 1].data = [{ value: '', valueDefault: '', select: false }];
                  }
                }
                else if (L_cp[L_cp.length - 1].data.length < 1) {
                  L_cp[L_cp.length - 1].data = [{ value: '', valueDefault: '', select: false }];
                }
                if (L_cp[L_cp.length - 1].type === 'CLA' || L_cp[L_cp.length - 1].type === 'DIC' || L_cp[L_cp.length - 1].type === 'LST') {
                  L_cp[L_cp.length - 1].data = L_cp[L_cp.length - 1].data.sort((a, b) => {
                    if (a.value < b.value) {
                      return -1;
                    }
                    if (a.value > b.value) {
                      return 1;
                    }
                    return 0;
                  });
                }
                for (let L_parcours_data = 0; L_parcours_data < L_cp[L_cp.length - 1].data.length; L_parcours_data++) {
                  if (L_valeur_suite.indexOf(L_cp[L_cp.length - 1].data[L_parcours_data].value) > 0) {
                    L_cp[L_cp.length - 1].data[L_parcours_data].select = true;
                  }
                  else {
                    L_cp[L_cp.length - 1].data[L_parcours_data].select = false;
                  }
                }
              }

              break;
            }
          }
        }
        data.documents[0].CP = L_cp;
        data.documents[0].CP_REMPLI = L_cp.filter(cpUn => {
          return cpUn.data.filter(cpValues => { return cpValues.select === true }).length > 0;
        }).length;

        L_documentsUn = Object.assign({}, this.state.documentUn);
        L_documentsUn.data = data.documents[0];

        L_documentsUn.data.IMAGE = "";
        L_documentsUn.data.ETOILE = parseInt(L_documentsUn.data.STAR);
        if (isNaN(L_documentsUn.data.ETOILE)) {
          L_documentsUn.data.ETOILE = -1;
        }
        if (L_documentsUn.data.IMG?.length) {
          L_documentsUn.data.IMAGE = L_documentsUn.data.IMG[0];
        }
        else {
          L_documentsUn.data.IMG = [];
        }
        //let L_v_fact = L_documentsUn.data.V_FACT;
        if (L_documentsUn.data.V_FACT === null || typeof L_documentsUn.data.V_FACT === 'undefined') {
          L_documentsUn.data.V_FACT = { STATUS: null, STATE: null };
        }
        if (L_documentsUn.data.V_APL === null || typeof L_documentsUn.data.V_APL === 'undefined') {
          L_documentsUn.data.V_APL = [];
        }
        if (L_documentsUn.data.UFLD === null || typeof L_documentsUn.data.UFLD === 'undefined') {
          L_documentsUn.data.UFLD = {};
        }
        if (L_documentsUn.data.ADB === null || typeof L_documentsUn.data.ADB === 'undefined') {
          L_documentsUn.data.ADB = '';
        }
        if (L_documentsUn.data.EPRD === null || typeof L_documentsUn.data.EPRD === 'undefined') {
          L_documentsUn.data.EPRD = '';
          if (L_documentsUn.data.PRD !== null && typeof L_documentsUn.data.PRD != 'undefined') {
            L_documentsUn.data.EPRD = L_documentsUn.data.PRD.split('<br/>')[0];
          }
        }
        if (L_documentsUn.data.REP === null || typeof L_documentsUn.data.REP === 'undefined') {
          L_documentsUn.data.REP = '';
        }
        if (L_documentsUn.data.DESC === null || typeof L_documentsUn.data.DESC === 'undefined') {
          L_documentsUn.data.DESC = '';
        }
        if (L_documentsUn.data.TI === null || typeof L_documentsUn.data.TI === 'undefined') {
          L_documentsUn.data.TI = '';
        }
        if (L_documentsUn.data.AB === null || typeof L_documentsUn.data.AB === 'undefined') {
          L_documentsUn.data.AB = '';
        }
        if (L_documentsUn.data.ECLM === null || typeof L_documentsUn.data.ECLM === 'undefined') {
          L_documentsUn.data.ECLM = '';
        }
        if (L_documentsUn.data.CLMS === null || typeof L_documentsUn.data.CLMS === 'undefined') {
          L_documentsUn.data.CLMS = '';
        }
        if (L_documentsUn.data.ICLM === null || typeof L_documentsUn.data.ICLM === 'undefined') {
          L_documentsUn.data.ICLM = '';
        }
        if (L_documentsUn.data.OBJ === null || typeof L_documentsUn.data.OBJ === 'undefined') {
          L_documentsUn.data.OBJ = '';
        }
        if (L_documentsUn.data.ODES === null || typeof L_documentsUn.data.ODES === 'undefined') {
          L_documentsUn.data.ODES = '';
        }
        if (L_documentsUn.data.IN !== null && typeof L_documentsUn.data.IN !== 'undefined') {
          L_documentsUn.data.IN_S = L_documentsUn.data.IN.split(';');
        }
        else {
          L_documentsUn.data.IN_S = [];
        }
        if (L_documentsUn.data.PA !== null && typeof L_documentsUn.data.PA !== 'undefined') {
          L_documentsUn.data.PA_S = L_documentsUn.data.PA.split('<br/>')
        }
        else {
          L_documentsUn.data.PA_S = [];
        }
        if (L_documentsUn.data.IC !== null && typeof L_documentsUn.data.IC !== 'undefined') {
          L_documentsUn.data.IC_S = L_documentsUn.data.IC.split('<br/>');
        }
        else {
          L_documentsUn.data.IC_S = [];
        }
        if (L_documentsUn.data.CPC !== null && typeof L_documentsUn.data.CPC !== 'undefined') {
          L_documentsUn.data.CPC_S = L_documentsUn.data.CPC.split('<br/>');
        }
        else {
          L_documentsUn.data.CPC_S = [];
        }
        if (L_documentsUn.data.CTN === null || typeof L_documentsUn.data.CTN === 'undefined') {
          L_documentsUn.data.CTN = '';
        }
        if (L_documentsUn.data.V_APL === null || typeof L_documentsUn.data.V_APL === 'undefined') {
          L_documentsUn.data.V_APL = [];
        }
        L_documentsUn.data.FPN_CODE = {};
        if (L_documentsUn.data.V_PTCC !== null && typeof L_documentsUn.data.V_PTCC !== 'undefined') {
          if (L_documentsUn.data.V_PTCC.length === 2) {
            for (let L_parcours_ptcc = 0; L_parcours_ptcc < L_documentsUn.data.V_PTCC[0].GRANTED.length; L_parcours_ptcc++) {
              L_documentsUn.data.FPN_CODE[L_documentsUn.data.V_PTCC[0].GRANTED[L_parcours_ptcc].CC] = L_documentsUn.data.V_PTCC[0].GRANTED[L_parcours_ptcc].GRANTED;
            }
            for (let L_parcours_ptcc = 0; L_parcours_ptcc < L_documentsUn.data.V_PTCC[1].PENDING.length; L_parcours_ptcc++) {
              L_documentsUn.data.FPN_CODE[L_documentsUn.data.V_PTCC[1].PENDING[L_parcours_ptcc].CC] = L_documentsUn.data.V_PTCC[1].PENDING[L_parcours_ptcc].PENDING;
            }
          }
        }
        // cited
        L_documentsUn.data.CITATION_TAB = [0, 0];
        L_documentsUn.data.etatPatentCitation = Constante.etat.initial;
        L_documentsUn.data.etatPatentCiting = Constante.etat.initial;

        // citing
        L_documentsUn.data.CITED_BY = 0;
        if (L_documentsUn.data.CTGN !== null) {
          L_documentsUn.data.CITED_BY = L_documentsUn.data.CTGN.length;
        }

      }
      // changement document actif
      if (L_documentsUn.data.APID !== this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentActif) {
        this.activeDoc(L_documentsUn.data.APID);
      }
      if (this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.WORKFILE && L_documentsUn !== null) {
        this.loadComment(L_documentsUn);
      }
      else {
        L_documentsUn.etat = Constante.etat.charge
        this.setState({
          documentUn: L_documentsUn
        });
      }
    });
  }
}

loadComment = (P_document) => {
  let L_var = {
    'ticket': this.props.ticket,
    'shard': this.props.shard,
    'id': P_document.data.ID,
    'emplacement': this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix],
    'idrecherche': this.props.RechercheInfo.IdRecherche
  };
  AjaxGestion(Constante.AjaxChoix.commentGet, L_var).then((data) => {
    if (data.notes !== null) {
      for (let L_parcours_note = 0; L_parcours_note < data.notes.length; L_parcours_note++) {
        data.notes[L_parcours_note].text = data.notes[L_parcours_note].text.replace(/<b>/gi, '<strong>')
          .replace(/<\/b>/gi, '</strong>')
          .replace(/<i>/gi, '<em>')
          .replace(/<\/i>/gi, '</em>');
      }
      P_document.data.Comment = data.notes;
      this.loadInfosWorkfile(P_document);
    }
    else {
      P_document.etat = Constante.etat.charge
      this.setState({
        documentUn: P_document
      });
    }
  });
}

loadInfosWorkfile = (P_document) => {
  let L_var = {
    'ticket': this.props.ticket,
    'shard': this.props.shard,
    'docID': P_document.data.APID,
    'emplacement': Constante.EmplacementRecherche.FAMPAT,
    'idrecherche': '',
    'query': P_document.data.APID + '/APID',
    'NBResultat': '10',
    'Ordre': Constante.RequeteOrdre.Relevance
  };
  AjaxGestion(Constante.AjaxChoix.unDoc, L_var).then((data) => {
    if (data.documents !== null && typeof data.documents !== 'undefined') {
      if (data.documents.length > 0) {
        P_document.data.DESC = data.documents[0].DESC;
        //P_document.data.CLM = data.documents[0].CLM;
        P_document.data.CLMS = data.documents[0].CLMS;
        //P_document.data.ECLM = data.documents[0].ECLM;
        P_document.data.V_FACT = data.documents[0].V_FACT;
        P_document.data.V_APL = data.documents[0].V_APL;
        if (P_document.data.DESC === null || typeof P_document.data.DESC === 'undefined') {
          P_document.data.DESC = '';
        }
        if (P_document.data.CLMS === null || typeof P_document.data.CLMS === 'undefined') {
          P_document.data.CLMS = '';
        }
        if (P_document.data.V_FACT === null || typeof P_document.data.V_FACT === 'undefined') {
          P_document.data.V_FACT = { STATUS: null, STATE: null };
        }
        if (P_document.data.V_APL === null || typeof P_document.data.V_APL === 'undefined') {
          P_document.data.V_APL = {};
        }
      }
    }
    P_document.etat = Constante.etat.charge
    this.setState({
      documentUn: P_document
    });
  });
}

addAlert = P_code_alert => {
  if (P_code_alert === Constante.dialogMode.addSelect) {
    // ajout Documents à la watching list
    if (this.props.watchingListId === null) {
      window.document.body.style.cursor = "wait";
      // recup ID de la liste
      this.alertCheckWatchingList(P_code_alert);
    } else {
      this.dialogAlert.current.dialogVisible(true);
      this.setState({ AlertDialogType: P_code_alert })
    }
    //console.log("list id is:" + this.state.watchingListId);
  } else {
    this.dialogAlert.current.dialogVisible(true);
    this.setState({ AlertDialogType: P_code_alert })
  }
}

/*
*
* function who count the number of alert
*
* */
alertGetCount = () => {
  // verification presence liste watching list
  var L_variable = {
    ticket: this.props.ticket,
    shard: this.props.shard
  }
  AjaxGestion(Constante.AjaxChoix.alertes, L_variable).then((data) => {
    if (data === null || typeof data === 'undefined') {
      console.log('Error data !');
      return;
    }
    if (typeof data.erreur !== 'undefined') {
      console.log('Error code :' + data.erreur + ' info :' + data.log);
      return;
    }
    this.setState({ alertCount: data.length })

  });
}

alertCheckWatchingList = (P_code_alert) => {
  // verification presence liste watching list
  var L_variable = {
    ticket: this.props.ticket,
    shard: this.props.shard,
    type: "LIST",
    id: 0
  }
  AjaxGestion(Constante.AjaxChoix.ProjetsGet, L_variable).then((data) => {
    if (data === null || typeof data === 'undefined') {
      console.log('Error data !');
      return;
    }
    if (typeof data.erreur !== 'undefined') {
      console.log('Error code :' + data.erreur + ' info :' + data.log);
      return;
    }
    //console.log(JSON.stringify(data));
    if (data.data === null) {
      console.log(data.message);
      return;
    }
    // INBOX en premier
    let inbox = data[0].children;
    let inbox_id = data[0].id;
    for (let i = 0; i < inbox.length; i++) {
      let obj = inbox[i];
      if (obj.name === "_OrbitExpressPatentWatching_") {
        this.props.setwatchingListId(obj.id);
        this.dialogAlert.current.dialogVisible(true);
        this.setState({ AlertDialogType: P_code_alert })
        window.document.body.style.cursor = "default";
      }
    }
    console.log("WATCHING LIST ID IS:" + this.props.watchingListId);
    if (this.props.watchingListId === null) {
      // creation liste et alerte
      this.alertAddWatchingList(inbox_id);
      this.setState({ AlertDialogType: P_code_alert })
    }
    return null;
  });
}

alertAddWatchingList = (P_parent) => {
  // ajout alerte watching list
  var L_variable = {
    ticket: this.props.ticket,
    shard: this.props.shard,
    parentID: P_parent,
    type_folder_list: "LIST",
    name: "_OrbitExpressPatentWatching_"
  }
  AjaxGestion(Constante.AjaxChoix.ProjetsAdd, L_variable).then((data) => {
    if (data === null || typeof data === 'undefined') {
      console.log('Error data !');
      return;
    }
    if (typeof data.erreur !== 'undefined') {
      console.log('Error code :' + data.erreur + ' info :' + data.log);
      return;
    }
    //console.log(JSON.stringify(data));
    if (data.data === null) {
      console.log(data.message);
    }
    else {
      //creation alerte
      console.log("LIST:" + data.data.id + " HAS BEEN CREATED");
      this.props.setwatchingListId(data.data.id);
      this.alertAddMonitor(data.data.id);
    }
  });
}

alertAddMonitor = (P_ID) => {
  // ajout alerte watching list
  let l_query = "*MEM \"" + P_ID.toUpperCase() + "\"/XPN/APID";
  var L_variable = {
    ticket: this.props.ticket,
    shard: this.props.shard,
    query: l_query,
    email: this.props.user.email,
    name: "_ORBITEXPRESSPATENTWATCHING_",
    period: "UE"
  }
  AjaxGestion(Constante.AjaxChoix.AlerteAdd, L_variable).then((data) => {
    if (data === null || typeof data === 'undefined') {
      console.log('Error data !');
      return;
    }
    if (typeof data.erreur !== 'undefined') {
      console.log('Error code :' + data.erreur + ' info :' + data.log);
      return;
    }
    // console.log(JSON.stringify(data));
    if (data.data === null) console.log(data.message);
    this.dialogAlert.current.dialogVisible(true);
    window.document.body.style.cursor = "default";
    console.log("MONITOR ALERT HAS BEEN CREATED");
  });
}

alertMonitorValider = () => {
  //console.log("alertMonitorValider");
  if (this.state.alertIndex === -1) return;
  var L_variable = {
    ticket: this.props.ticket,
    shard: this.props.shard,
    id: this.props.watchingListId,
    DocumentsSelect: this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect,
    EmplacementRecherche: Constante.EmplacementRecherche.LIST,
  }

  if (this.state.affichage.mode === Constante.affichageMode.listedoc) {
    L_variable.DocumentsSelect = [this.state.documentUn.data.APID];
  }

  AjaxGestion(Constante.AjaxChoix.listAdd, L_variable).then((data) => {
    if (data === null || typeof data === 'undefined') {
      console.log('Error data !');
      return;
    }
    if (typeof data.erreur !== 'undefined') {
      console.log('Error code :' + data.erreur + ' info :' + data.log);
      return;
    }
    //console.log(JSON.stringify(data));
  });
}

alertAddValider = (P_new_name, P_new_period) => {
  if (this.state.alertIndex === -1) return;
  // test si la requete est déjà dans un champ
  let l_query = this.props.RequeteInfosActif.requete;
  if (l_query.indexOf("/") === -1) l_query += "/BI/SA/IN/PA";
  var L_variable = {
    ticket: this.props.ticket,
    shard: this.props.shard,
    query: l_query,
    email: this.props.user.email,
    name: P_new_name,
    period: P_new_period
  }
  window.document.body.style.cursor = "wait";
  AjaxGestion(Constante.AjaxChoix.AlerteAdd, L_variable).then((data) => {
    if (data === null || typeof data === 'undefined') {
      console.log('Error data !');
      return;
    }
    if (typeof data.erreur !== 'undefined') {
      console.log('Error code :' + data.erreur + ' info :' + data.log);
      return;
    }
    // console.log(JSON.stringify(data));
    // data contient l'alerte ajoutée ou erreur
    // {"message":"Search Strategy Name already exists!","data":null}
    if (data.data === null) {
      console.log(data.message);
    }
    if (data.data !== null) {
      this.setState({
        alertCount: this.state.alertCount + 1
      });
    }
    window.document.body.style.cursor = "default";
    let l_lang = this.props.langue.listedocaction;
    alert(l_lang[18].LIB);
  });

}

sendPermaLink = () => {
  this.props.active_beforeunloadfct(false);
  let l_lang = this.props.langue.listedocaction;
  let l_body = l_lang[12].LIB + "\n\n" + l_lang[13].LIB + " " + this.state.documentUn.data.REP + " " + l_lang[14].LIB + "\n" + this.state.documentUn.data.PERMALINK + "\n\n" + l_lang[15].LIB;
  let l_title = this.state.documentUn.data.TI.replace(/<b>/gi, '').replace(/<\/b>/gi, '')
  document.location.href = "mailto:?subject=" + l_title + "&body=" + encodeURIComponent(l_body);
  this.props.active_beforeunloadfct(true);
}


/********************
 *      CP          *
 * ******************/
cp_edit_mode = (P_index_cp) => {
  if (P_index_cp < this.state.documentUn.data.CP.length) {
    let L_doc = this.state.documentUn;
    L_doc.data.CP[P_index_cp].EditMode = !L_doc.data.CP[P_index_cp].EditMode;
    this.setState({
      documentUn: L_doc
    });
  }
}

cp_option_mode = (P_index_cp) => {
  if (P_index_cp < this.state.documentUn.data.CP.length) {
    let L_doc = this.state.documentUn;
    L_doc.data.CP[P_index_cp].OptionMode = !L_doc.data.CP[P_index_cp].OptionMode;
    this.setState({
      documentUn: L_doc
    });
  }
}

cp_save = (P_index_cp, P_tab_value) => {
  if (P_index_cp < this.state.documentUn.data.CP.length) {
    let L_var = {
      'ticket': this.props.ticket,
      'shard': this.props.shard,
      'id': this.state.documentUn.data.ID,
      'emplacement': this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix],
      'idrecherche': this.props.RechercheInfo.IdRecherche,
      'value': (P_tab_value !== null) ? P_tab_value : [""],//[this.state.documentUn.data.CP[P_index_cp].Ref.current.get_comment()],
      'field': this.state.documentUn.data.CP[P_index_cp].name
    };
    AjaxGestion(Constante.AjaxChoix.cpDocValueUpdate, L_var).then((data) => {
      let L_doc = this.state.documentUn;
      switch (L_doc.data.CP[P_index_cp].type) {
        case 'CLA':
        case 'DIC':
        case 'LST':
          let L_data_tab = JSON.parse(JSON.stringify(L_doc.data.CP[P_index_cp].data));
          L_data_tab.map((P_value) => {
            P_value.select = false;
            if (P_tab_value !== null) {
              if (P_tab_value.indexOf(P_value.value) >= 0) {
                P_value.select = true;
              }
            }
            return P_value;
          })
          L_doc.data.CP[P_index_cp].data = L_data_tab;

          break;
        case 'DAT':
        case 'NUM':
        case 'LNK':
          L_doc.data.CP[P_index_cp].data[0].value = P_tab_value[0];
          L_doc.data.CP[P_index_cp].data[0].valueDefault = P_tab_value[0];
          L_doc.data.CP[P_index_cp].Open = false;
          L_doc.data.CP[P_index_cp].data[0].select = false;
          if (P_tab_value[0].length > 0) {
            L_doc.data.CP[P_index_cp].data[0].select = true;
          }
          break;
        case 'TXT':
          L_doc.data.CP[P_index_cp].data[0].value = P_tab_value[0];
          L_doc.data.CP[P_index_cp].data[0].valueDefault = P_tab_value[0];
          L_doc.data.CP[P_index_cp].EditMode = false;
          L_doc.data.CP[P_index_cp].OptionMode = false;
          L_doc.data.CP[P_index_cp].data[0].select = false;
          if (P_tab_value[0].length > 0) {
            L_doc.data.CP[P_index_cp].data[0].select = true;
          }
          break;
        default:
          break;
      }
      L_doc.data.CP_REMPLI = L_doc.data.CP.filter(cpUn => {
        return cpUn.data.filter(cpValues => { return cpValues.select === true }).length > 0;
      }).length;
      this.setState({
        documentUn: L_doc
      });
    });
  }
}

set_random = () => {
  let anysize = 4;//the size of string
  let charset = "abcdefghijklmnopqrstuvwxyz"; //from where to create
  let i = 0;
  let L_random = '';
  while (i++ < anysize) {
    L_random += charset.charAt(Math.random() * charset.length)
  }
  return L_random;
}

cp_value_valide = (P_cp_temp, P_cp_index, P_value) => {
  this.CPInfo = P_cp_temp;
  this.CPInfo.valueadd = P_value;

  if (this.state.documentUn.data.CP[P_cp_index].name !== P_cp_temp.name) {
    return;
  }

  let L_var = {
    'ticket': this.props.ticket,
    'shard': this.props.shard,
    'id': P_cp_temp.name,
    'emplacement': this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix],
    'idrecherche': this.props.RechercheInfo.IdRecherche,
    'value': {
      "data": [
        {
          "value": P_value,
          "desc": P_value
        }
      ]
    }

  }

  AjaxGestion(Constante.AjaxChoix.cpAddValue, L_var).then((data) => {
    if (data.code !== 'null') {
    }
    let L_doc = this.state.documentUn;
    for (let L_parcours_cp = 0; L_parcours_cp < L_doc.data.CP.length; L_parcours_cp++) {
      if (L_doc.data.CP[L_parcours_cp].name === this.CPInfo.name) {
        L_doc.data.CP[L_parcours_cp].data.push({
          value: this.CPInfo.valueadd,
          select: false
        });
      }
    }
    this.setState({
      documentUn: L_doc
    }, () => {
      if (this.DocumentInfoRef.current !== null) {
        this.DocumentInfoRef.current.CP_manage_valide(4, 0);
      }
    })
  });

}

cp_modif_valide = (P_cp_temp) => {
  this.CPInfo = P_cp_temp;
  let L_CP_create = {
    ticket: this.props.ticket,
    label: P_cp_temp.display,
    code: P_cp_temp.name.split('.')[1],
    type: P_cp_temp.type,
    sample: P_cp_temp.display,
    range: 1,
    userFieldValues: []
  };
  if (P_cp_temp.type === "LST" || P_cp_temp.type === "CLA" || P_cp_temp.type === "DIC") {
    for (let L_parcours_cp_temp = 0; L_parcours_cp_temp < P_cp_temp.data.length; L_parcours_cp_temp++) {
      L_CP_create.userFieldValues.push({
        code: this.set_random(),
        value: P_cp_temp.data[L_parcours_cp_temp].value,
        description: "",
        descriptionFR: "",
        status: 0,
        previousStatus: 0,
        error: 0,
        label: P_cp_temp.data[L_parcours_cp_temp].value,
        display: P_cp_temp.data[L_parcours_cp_temp].value,
        userCredential: null
      });
    }
  }

  let L_var = {
    'ticket': this.props.ticket,
    'shard': this.props.shard,
    'id': this.state.documentUn.data.ID,
    'emplacement': this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix],
    'idrecherche': this.props.RechercheInfo.IdRecherche,
    'value': L_CP_create,
  }

  AjaxGestion(Constante.AjaxChoix.cpUpdate, L_var).then((data) => {
    if (data.code !== 'null') {
    }
    let L_doc = this.state.documentUn;
    for (let L_parcours_cp = 0; L_parcours_cp < L_doc.data.CP.length; L_parcours_cp++) {
      if (L_doc.data.CP[L_parcours_cp].name === this.CPInfo.name) {
        L_doc.data.CP[L_parcours_cp] = this.CPInfo;
      }
    }
    this.setState({
      documentUn: L_doc
    }, () => {
      if (this.DocumentInfoRef.current !== null) {
        this.DocumentInfoRef.current.CP_manage_valide(2, 0);
      }
    })
  });
}

cp_delete_valide = (P_cp_temp, P_cp_index) => {
  this.CPInfo = P_cp_temp;
  this.CPInfo.index = P_cp_index;
  P_cp_temp.uuid = "123456789a";
  let L_var = {
    'ticket': this.props.ticket,
    'shard': this.props.shard,
    'id': P_cp_temp.uuid,
    'emplacement': this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix],
    'idrecherche': this.props.RechercheInfo.IdRecherche
  }
  AjaxGestion(Constante.AjaxChoix.cpDelete, L_var).then((data) => {
    //if (data.code !== 'null') {
    let L_doc = this.state.documentUn;
    //if (this.CPInfo.uuid === L_doc.data.CP[this.CPInfo.index].uuid) {
    L_doc.data.CP.splice(this.CPInfo.index, 1);
    //}
    this.setState({
      documentUn: L_doc
    }, () => {
      if (this.DocumentInfoRef.current !== null) {
        this.DocumentInfoRef.current.CP_manage_valide(3, this.CPInfo.index);
      }
    })
    //}
  });
}

cp_create_valide = (P_cp_temp) => {
  this.CPInfo = P_cp_temp;
  let L_CP_create = {
    ticket: this.props.ticket,
    label: P_cp_temp.display,
    code: 'ZOPE', // this.set_random()
    type: P_cp_temp.type,
    sample: P_cp_temp.display,
    range: 1,
    userFieldValues: []
  };
  if (P_cp_temp.type === "LST" || P_cp_temp.type === "CLA" || P_cp_temp.type === "DIC") {
    for (let L_parcours_cp_temp = 0; L_parcours_cp_temp < P_cp_temp.data.length; L_parcours_cp_temp++) {
      L_CP_create.userFieldValues.push({
        code: this.set_random(),
        value: P_cp_temp.data[L_parcours_cp_temp].value,
        description: "",
        descriptionFR: "",
        status: 0,
        previousStatus: 0,
        error: 0,
        label: P_cp_temp.data[L_parcours_cp_temp].value,
        display: P_cp_temp.data[L_parcours_cp_temp].value,
        userCredential: null
      });
    }
  }

  let L_var = {
    'ticket': this.props.ticket,
    'shard': this.props.shard,
    'id': this.state.documentUn.data.ID,
    'emplacement': this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix],
    'idrecherche': this.props.RechercheInfo.IdRecherche,
    'value': L_CP_create,
  }

  AjaxGestion(Constante.AjaxChoix.cpAdd, L_var).then((data) => {
    //if (data.code !== 'null') {
    if (this.CPInfo.type === 'TXT') {
      this.CPInfo.Ref = React.createRef();
      this.CPInfo.EditMode = false;
      this.CPInfo.OptionMode = false;
    }
    if (P_cp_temp.data === null || typeof this.CPInfo.data === "undefined") {
      this.CPInfo.data = [{ value: '' }];
    }
    for (let L_parcours_data = 0; L_parcours_data < this.CPInfo.data.length; L_parcours_data++) {
      this.CPInfo.data[L_parcours_data].select = false;
    }
    let L_doc = this.state.documentUn;
    L_doc.data.CP.push(this.CPInfo);
    this.setState({
      documentUn: L_doc
    }, () => {
      if (this.DocumentInfoRef.current !== null) {
        this.DocumentInfoRef.current.CP_manage_valide(1, this.state.documentUn.data.CP.length - 1);
      }
    })
    //}
  });
}


// NOT USED
/*
cp_get_fields = () => {
  let L_var = {
    'ticket': this.props.ticket,
    'shard': this.props.shard,
    'id': this.state.documentUn.data.ID,
    'emplacement': this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix],
    'idrecherche': this.props.RechercheInfo.IdRecherche
  }
  AjaxGestion(Constante.AjaxChoix.cpGetAll, L_var).then((data) => {
    if (data.fields !== null) {
      let L_doc = this.state.documentUn;
      switch (this.CPInfo.Mode) {
        case 1:
          break;
        case 2:
          break;
        case 3:
          break;
        default:
          break;
      }
      this.setState({
        documentUn: L_doc
      }, () => {
        if (this.DocumentInfoRef.current !== null) {
          this.DocumentInfoRef.current.CP_manage_valide(1, this.state.documentUn.data.CP.length - 1);
        }
      })
    }
  });
}
*/


/**
 * isListDoc
 *
 * @returns {boolean}
 */
isListDoc() {
  const { affichage } = this.state,
    { mode } = affichage;

  return mode === 'listedoc';
}


/**
 * Render links to change the type of the query
 *
 * @return JSX
 */
changeQueryLinksRender() {
  const isListDoc = this.isListDoc(),
    isAdvancedSearch = this.props.affichageMode === 'rechercheplus',
    isSemanticSearch = this.props.RequeteInfos.choixRecherche === 2;

  return !isListDoc ? (
    <div className="search-selector-tab">
      {
        !isAdvancedSearch ? (
          <span
            className="search-mode-link"
            onClick={() => this.props.changement_requete_avancee(Constante.RequeteAvancee.modeAvancee, {})}
          >
            {this.props.langueRechercheavancee[0].LIB}
          </span>
        ) : (
            <span
              className="search-mode-link"
              onClick={() => this.props.changement_requete_avancee(Constante.RequeteAvancee.modeSimple, {})}
            >
              {this.props.langueRechercheavancee[74].LIB}
            </span>
          )
      }
      {
        isSemanticSearch ? (
          <span
            className="search-mode-link"
            onClick={() => this.props.changement_requete_avancee(Constante.RequeteAvancee.modeSimple, {})}
          >
            {this.props.langueRechercheavancee[74].LIB}
          </span>
        ) : (
            <span
              className="search-mode-link"
              onClick={() => this.props.changement_requete_avancee(Constante.RequeteAvancee.modeSemantique, {})}
            >
              {this.props.langueRechercheavancee[31].LIB}
            </span>
          )
      }
    </div>
  ) : null;
}



render() {
  const { highlightsProfile, updateHighlightsProfile } = this.props,
    isListDoc  = this.isListDoc(),
    currentDoc  = Object.assign({}, this.state.documentUn);

  let L_nom_reccherche = this.props.RechercheInfo.NomRecherche;
  if (L_nom_reccherche.length > 40) {
    L_nom_reccherche = L_nom_reccherche.substring(0, 40) + '...';
  }
  else if (this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SEMANTIQUE) {
    L_nom_reccherche = this.props.langue.listedoc[19].LIB;
  }
  else if (this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SIMILARITY) {
    L_nom_reccherche = this.props.langue.listedoc[20].LIB;
  }
  else if (this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.PRIOR_ART) {
    L_nom_reccherche = this.props.langue.listedoc[28].LIB;
  }
  let L_prec_type_texte = "";
  switch (this.props.RechercheInfo.EmplacementHistoriquePrecedentType) {
    case Constante.EmplacementRecherche.FAMPAT:
      L_prec_type_texte = this.props.langue.rechercheavancee[112].LIB;
      break;
    case Constante.EmplacementRecherche.SEMANTIQUE:
      L_prec_type_texte = this.props.langue.rechercheavancee[108].LIB;
      break;
    case Constante.EmplacementRecherche.SIMILARITY:
      L_prec_type_texte = this.props.langue.rechercheavancee[109].LIB;
      break;
    case Constante.EmplacementRecherche.PRIOR_ART:
      L_prec_type_texte = this.props.langue.rechercheavancee[107].LIB;
      break;
    case Constante.EmplacementRecherche.ALERT:
      L_prec_type_texte = this.props.langue.rechercheavancee[113].LIB;
      break;
    case Constante.EmplacementRecherche.WORKFILE:
      L_prec_type_texte = this.props.langue.rechercheavancee[111].LIB;
      break;
    case Constante.EmplacementRecherche.LIST:
      L_prec_type_texte = this.props.langue.rechercheavancee[110].LIB;
      break;
    default:
      break;
  }


  return (
    <div id="D_centreRecherche"
      className={(this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].etat === Constante.etat.encours ||
        this.state.documentUn.etat === Constante.etat.encours) ? this.state.affichage.mode + ' loadgif' : this.state.affichage.mode}
    >
      {this.changeQueryLinksRender()}
      <DialogAlert
        langue={this.props.langue.alertes}
        ref={this.dialogAlert}
        type={this.state.AlertDialogType}
        addAction={this.alertAddValider}
        addListAction={this.alertMonitorValider}
      />
      <DialogProjetDeleteDoc
        langue={this.props.langue.dialog}
        ref={this.dialogSuppDocListWorkfile}
        deleteDocListWorkfileValider={this.deleteDocListWorkfileValider}
      />
      <DialogProjetTreebeard
        langue={this.props.langueProjets}
        ref={this.dialogProjetTreebeard}
        ticket={this.props.ticket}
        shard={this.props.shard}
        user={this.props.user}
        addDocListWorkfileValider={this.addDocListWorkfileValider}
      />
      <div id="D_recherche_gauche_infos"
        style={this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.FAMPAT ? { display: 'none' } : {}}
      >
        <div id="D_recherche_gauche_infos_retour"
          style={(this.props.RechercheInfo.EmplacementHistoriquePrecedent === 0 ||
            this.props.RechercheInfo.EmplacementHistoriquePrecedent === this.props.RechercheInfo.EmplacementHistorique) ? { visibility: 'hidden' } : {}}
        >
          <div
            id="D_recherche_gauche_infos_retour_img_alerte"
            style={(this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.LIST &&
              this.props.RechercheInfo.IdRecherche.replace('/', '') === this.props.watchingListId) ? {} : { display: 'none' }}
            onClick={() => this.props.changeOnglet(Constante.affichagePage.alertes)}
          ></div>
          <span
            style={(this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.LIST &&
              this.props.RechercheInfo.IdRecherche.replace('/', '') === this.props.watchingListId) ? {} : { display: 'none' }}
            onClick={() => this.props.changeOnglet(Constante.affichagePage.alertes)}
          >
            {this.props.langue.rechercheavancee[42].LIB}
          </span>
          <div
            id="D_recherche_gauche_infos_retour_img_projet"
            style={((this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.LIST &&
              this.props.RechercheInfo.IdRecherche.replace('/', '') !== this.props.watchingListId) ||
              this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.WORKFILE) ? {} : { display: 'none' }}
            onClick={() => this.props.changeOnglet(Constante.affichagePage.alertes)}
          ></div>
          <span
            style={((this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.LIST &&
              this.props.RechercheInfo.IdRecherche.replace('/', '') !== this.props.watchingListId) ||
              this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.WORKFILE) ? {} : { display: 'none' }}
            onClick={() => this.props.changeOnglet(Constante.affichagePage.projets)}
          >
            {this.props.langue.rechercheavancee[43].LIB}
          </span>
          <div
            id="D_recherche_gauche_infos_retour_img_fampat"
            style={(this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SEMANTIQUE ||
              this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SIMILARITY ||
              this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.PRIOR_ART) ? {} : { display: 'none' }}
            onClick={() => this.search_prec(this.props.RechercheInfo.EmplacementHistoriquePrecedent)}
          ></div>
          <span
            style={(this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SEMANTIQUE ||
              this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SIMILARITY ||
              this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.PRIOR_ART) ? {} : { display: 'none' }}
            onClick={() => this.search_prec(this.props.RechercheInfo.EmplacementHistoriquePrecedent)}
          >
            {L_prec_type_texte}
          </span>
        </div>
        <div id="D_recherche_gauche_infos_emplacement">
          {L_nom_reccherche}
        </div>
      </div>
      <div id="D_recherche_milieu"
        style={this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.FAMPAT ? { maxHeight: '100%' } : { maxHeight: 'calc( 100% - 3rem)' }}

      >
        <div id="D_recherche_gauche"
          style={this.state.affichage.RechercheAvancee === 1 ? { display: 'inline' } : { display: 'none' }}
        >
          <div id="D_recherche_gauche_bas">
            <RechercheAvancee
              ref={this.rechercheavancee}
              langue={this.props.langue.rechercheavancee}
              affichage={this.state.affichage}
              RequeteInfosListe={this.props.RequeteInfosListe}
              RequeteInfos={this.props.RequeteInfos}
              RechercheInfo={this.props.RechercheInfo}
              changement_requete_avancee={this.props.changement_requete_avancee}
              changeDroiteRechercheAvancee={this.changeDroiteRechercheAvancee}
              changeDroiteRechercheAvanceeClose={this.changeDroiteRechercheAvanceeClose}
              requeteHistoryAction={this.props.requeteHistoryAction}
            />
          </div>
        </div>
        <div id="D_recherche_centre_et_droite">
          <div
            id="D_recherche_centre_et_droite_choix_p_npl"
            style={(this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.FAMPAT || true ||
              this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.NPL) ? {} : { display: "none" }} >
            <div
              id="D_recherche_centre_et_droite_choix_p"
              className={this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] !== Constante.EmplacementRecherche.NPL ? "select" : ""}
              onClick={() => this.search_change_mode_patent_npl('1')}
            >
              {this.props.langue.listedoc[23].LIB}
            </div>
            <div
              id="D_recherche_centre_et_droite_choix_npl"
              className={this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.NPL ? "select" : ""}
              onClick={() => this.search_change_mode_patent_npl('2')}
            >
              {this.props.langue.listedoc[24].LIB}
            </div>
          </div>
          <div
            id="D_recherche_centre_et_droite_infos"
          >
            <ListeDoc
              translations={this.props.langue}
              languelistedocaction={this.props.langue.listedocaction}
              langue={this.props.langue.listedoc}
              highlightsProfile={highlightsProfile}
              updateHighlightsProfile={updateHighlightsProfile}
              langueProjets={this.props.langueProjets}
              requete_highlight={this.props.RequeteInfos.requete_highlight}
              DocumentsInfo={this.state.DocumentsInfo}
              RechercheInfo={this.props.RechercheInfo}
              affichage={this.state.affichage}
              user={this.props.user}
              NPLConcept={(this.props.RequeteInfosActif === null) ? '' : this.props.RequeteInfosActif.requete_avancee.NPLConcept}
              changeNbresultat={this.changeNbresultat}
              selectDoc={this.selectDoc}
              activeDoc={this.activeDoc}
              voirDoc={this.voirDoc}
              exportDoc={this.exportDoc}
              changePage={this.changePage}
              changeOrdre={this.changeOrdre}
              changeDroiteAffichage={this.changeDroiteAffichage}
              changeDroiteRechercheAvancee={this.changeDroiteRechercheAvancee}
              clearRechercheEmplacement={this.clearRechercheEmplacement}
              alertCount={this.state.alertCount}
              addAlert={this.addAlert}
              noteDoc={this.noteDoc}
              addDocListWorkfile={this.addDocListWorkfile}
              deleteDocListWorkfile={this.deleteDocListWorkfile}
              similariteSearch={this.similariteSearch}
              openInnovationDashboard={this.openInnovationDashboard}
            />
            <div id="D_droite"
              style={this.state.affichage.droite === 1 ? {} : { display: 'none' }}
              onKeyDown={this.pageKeyPress}
              tabIndex="0"
            >
              <SelectAffichage
                langue={this.props.langue.selectaffichage}
                affichage={this.state.affichage}
                EmplacementRecherche={this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix]}
                NomRecherche={this.props.RechercheInfo.NomRecherche}
                changeSelectOuMode={this.changeSelectOuMode}
                user={this.props.user}
                currentDoc={currentDoc}
              />
              { // Highlight configurator button
                isListDoc
                  ? (
                    <HighlightConfigurator
                      updateProfile={updateHighlightsProfile}
                      profile={highlightsProfile}
                      translations={this.props.langue}
                      width={300}
                      tooltipOffset={{ left: 100 }}
                    />
                  )
                  : null
              }
              <DocumentParcours
                langue={this.props.langue.listedocaction}
                documentsNB={this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBDocs}
                docAfficheposition={this.state.documentUn.position}
                documentvisible={this.state.affichage.document}
                visionneusevisible={this.state.affichage.visioneuse}
                affichage={this.state.affichage}
                user={this.props.user}
                EmplacementRecherche={this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix]}
                NomRecherche={this.props.RechercheInfo.NomRecherche}
                labelListAlert={this.props.langue.alertes[4].LIB}
                changeSelectOuMode={this.changeSelectOuMode}
                voirDoc={this.voirDoc}
                exportDoc={this.exportDoc}
                changePage={this.changePage}
                changeOrdre={this.changeOrdre}
                changeDroiteAffichage={this.changeDroiteAffichage}
                changeDroiteRechercheAvancee={this.changeDroiteRechercheAvancee}
                clearRechercheEmplacement={this.clearRechercheEmplacement}
                alertCount={this.state.alertCount}
                addAlert={this.addAlert}
                sendPermaLink={this.sendPermaLink}
                addDocListWorkfile={this.addDocListWorkfile}
                deleteDocListWorkfile={this.deleteDocListWorkfile}
              />
              <div id="D_droite_affichage">
                <Graphe
                  ref={this.graph}
                  langue={this.props.langue.graphe}
                  nbDoc={this.state.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBDocs}
                  affichage={this.state.affichage}
                  user={this.props.user}
                  query={this.props.RequeteInfosActif}
                  ticket={this.props.ticket}
                  shard={this.props.shard}
                />
                <DocumentInfo
                  ref={this.DocumentInfoRef}
                  langue={this.props.langue.doc}
                  langueDialog={this.props.langue.dialog}
                  documentUn={this.state.documentUn}
                  affichage={this.state.affichage}
                  highlightsProfile={highlightsProfile}
                  EmplacementRecherche={this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix]}
                  NomRecherche={this.props.RechercheInfo.NomRecherche}
                  changeSelectOuMode={this.changeSelectOuMode}
                  PatentCitationLoad={this.PatentCitationLoad}
                  PatentCitingLoad={this.PatentCitingLoad}
                  user={this.props.user}
                  requete_highlight={this.props.RequeteInfos.requete_highlight}
                  noteDoc={this.noteDoc}
                  commentAdd={this.commentAdd}
                  commentUpdate={this.commentUpdate}
                  commentDelete={this.commentDelete}
                  similariteSearchUnDoc={this.similariteSearchUnDoc}
                  priorArtSearchUnDoc={this.priorArtSearchUnDoc}
                  commentMenuAction={this.commentMenuAction}
                  changeImageDocUn={this.changeImageDocUn}
                  cp_edit_mode={this.cp_edit_mode}
                  cp_option_mode={this.cp_option_mode}
                  cp_save={this.cp_save}
                  permalink={this.props.permalink}
                  base={this.props.base}
                  cp_create_valide={this.cp_create_valide}
                  cp_delete_valide={this.cp_delete_valide}
                  cp_modif_valide={this.cp_modif_valide}
                  cp_value_valide={this.cp_value_valide}
                />
                <DocumentVisioneuse
                  langue={this.props.langue.visioneuse}
                  documentUn={this.state.documentUn}
                  highlightsProfile={highlightsProfile}
                  affichage={this.state.affichage}
                  changeSelectOuMode={this.changeSelectOuMode}
                />
              </div>

              <div
                id="D_graphe_icone"
                className={this.state.affichage.graphe === 1 ? 'graphe_open' : 'graphe_close'}
                onClick={this.clickGrapheButton}
                style={(this.props.user.acces.graphe &&
                  this.state.affichage.mode === Constante.affichageMode.rechercheavancee &&
                  this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.FAMPAT) ? {} : { display: 'none' }}
              >
                <div id="D_graphe_icone_image"></div>
                <div id="D_graphe_icone_fond"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
}

export default Recherche;
